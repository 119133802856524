import { updateFocusDirection, updateFocusedMessage } from '@store/reducers/messages';
import { FocusDirection } from '@store/types/focused-message';

const FOCUS_DURATION = 1500;
let blurTimeout: number | undefined;

export function focusMessage(conversationId: number, messageId?: number) {
  if (blurTimeout) {
    clearTimeout(blurTimeout);
    blurTimeout = undefined;
  }
  blurTimeout = window.setTimeout(() => {
    updateFocusedMessage(conversationId);
    updateFocusDirection(undefined);
  }, FOCUS_DURATION);

  updateFocusedMessage(conversationId, messageId);
  updateFocusDirection(FocusDirection.Up);
}
