import { CloseOutlined, EnterOutlined } from '@ant-design/icons';
import type { Message } from '@interfaces/message';
import EmbeddedMessage from '@pages/ChatFeed/components/EmbeddedMessage';
import { selectInputMessageReplyInfo } from '@store/selectors/messages';
import { focusMessage } from '@utils/ui/focus-message';
import { Button } from 'antd';
import type { FC } from 'react';
import { memo } from 'react';

import styles from './ComposerEmbeddedMessage.module.scss';

interface OwnProps {
  conversationId: number;
  onClear?: () => void;
}

interface StateProps {
  inputMessageReplyInfo: Message;
}

const NonMemoComposerEmbeddedMessage: FC<OwnProps & StateProps> = ({
  inputMessageReplyInfo,
  onClear,
  conversationId,
}) => {
  return (
    <div className={styles.composerEmbeddedMessage}>
      <div className={styles.composerEmbeddedMessageInner}>
        <div className={styles.embeddedLeftIcon}>
          <EnterOutlined />
        </div>
        <EmbeddedMessage
          className="inside-input"
          onClick={() => {
            focusMessage(conversationId, inputMessageReplyInfo.id || inputMessageReplyInfo.localId);
          }}
          isEditedMessage={false}
          message={inputMessageReplyInfo}
        />
        <Button
          type="default"
          size="large"
          className={styles.embeddedCancel}
          icon={<CloseOutlined />}
          onClick={onClear}
        />
      </div>
    </div>
  );
};

const ComposerEmbeddedMessage = memo<OwnProps>(function ComposerEmbeddedMessage(props: OwnProps) {
  const inputMessageReplyInfo = selectInputMessageReplyInfo();
  return (
    <NonMemoComposerEmbeddedMessage
      onClear={props.onClear}
      inputMessageReplyInfo={inputMessageReplyInfo!}
      conversationId={props.conversationId}
    />
  );
});

export default ComposerEmbeddedMessage;
