import type { AuthContextType, User } from '@context/auth.context';
import { apiRequest, isResponse } from '@services/fetcher';
import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const response = await apiRequest<User>(`${import.meta.env.VITE_API_URL}/auth/login`, auth, {
      method: request.method,
      signal: request.signal,
      body: await request.formData(),
    });

    if (response instanceof Error) {
      throw response;
    }

    if (isResponse(response)) {
      return {
        status: response.status,
        statusText: response.statusText,
      } as Response;
    }

    auth.signIn(response);

    return json({
      ok: true,
    });
  }) satisfies ActionFunction;
