import type { ApiSticker } from '@interfaces/messages';

const STICKER_SIZE_INLINE_DESKTOP_FACTOR = 13;

const REM = parseInt(getComputedStyle(document.documentElement).fontSize, 10);

export function getStickerDimensions(sticker: ApiSticker) {
  const { width, height } = sticker;
  const aspectRatio = height / width;

  const baseWidth = REM * STICKER_SIZE_INLINE_DESKTOP_FACTOR;
  const calculatedHeight = aspectRatio ? baseWidth * aspectRatio : baseWidth;

  if (aspectRatio && calculatedHeight > baseWidth) {
    return {
      width: Math.round(baseWidth / aspectRatio),
      height: baseWidth,
    };
  }

  return {
    width: baseWidth,
    height: calculatedHeight,
  };
}
