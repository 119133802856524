import useLastCallback from '@hooks/use-last-callback';
import { createCallbackManager } from '@utils/callbacks';
import { useEffect } from 'react';

const blurCallbacks = createCallbackManager();
const focusCallbacks = createCallbackManager();

let isFocused = document.hasFocus();

window.addEventListener('blur', () => {
  if (!isFocused) {
    return;
  }

  isFocused = false;
  blurCallbacks.runCallbacks();
});

window.addEventListener('focus', () => {
  isFocused = true;
  focusCallbacks.runCallbacks();
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useBackgroundMode(onBlur?: (...args: any[]) => void, onFocus?: (...args: any[]) => void, isDisabled = false) {
  const lastOnBlur = useLastCallback(onBlur);
  const lastOnFocus = useLastCallback(onFocus);

  useEffect(() => {
    if (isDisabled) {
      return undefined;
    }

    if (!isFocused) {
      lastOnBlur();
    }

    blurCallbacks.addCallback(lastOnBlur);
    focusCallbacks.addCallback(lastOnFocus);

    return () => {
      focusCallbacks.removeCallback(lastOnFocus);
      blurCallbacks.removeCallback(lastOnBlur);
    };
  }, [isDisabled, lastOnBlur, lastOnFocus]);
}

export default useBackgroundMode;
