import { MenuOutlined } from '@ant-design/icons';
import trimText from '@helpers/trim-text';
import useLastCallback from '@hooks/use-last-callback';
import captureKeyboardListeners from '@utils/captureKeyboardListeners';
import type { MenuProps, MenuRef } from 'antd';
import { Button, Menu, Popover } from 'antd';
import type { FC, RefObject } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import styles from './MessageTemplatesPopover.module.scss';

const TEMPLATE_TRIM_LENGTH = 40;

export interface PopoverTemplate {
  id: number;
  command: string;
  template: string;
}

interface OwnProps {
  command: string | null;
  templates: PopoverTemplate[];
  popupContainerRef: RefObject<HTMLDivElement>;
  className?: string;
  onTemplateSelected: (template: string) => void;
  onClose: () => void;
}

const MessageTemplatesPopover: FC<OwnProps> = ({
  command,
  templates,
  popupContainerRef,
  className,
  onTemplateSelected,
  onClose,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedItemKey, setSelectedItemKey] = useState('');
  const menuRef = useRef<MenuRef>(null);

  useEffect(() => {
    setIsMenuOpen(isHovered);
    if (!isHovered && command === null) {
      setIsMenuOpen(false);
      return;
    }

    setIsMenuOpen(true);
  }, [isHovered, command]);

  const handleHoverChange = (hovered: boolean) => {
    setIsHovered(hovered);
  };

  const filteredItems = useMemo(() => {
    return command !== null
      ? templates.filter((item) => item.command.toLowerCase().includes(command.toLowerCase()))
      : templates;
  }, [command, templates]);

  const menuItems: MenuProps['items'] = filteredItems.map((item) => ({
    label: (
      <div key={item.id}>
        <strong style={{ display: 'block' }}>/{item.command}</strong>
        <small>{trimText(item.template, TEMPLATE_TRIM_LENGTH)}</small>
      </div>
    ),
    key: item.id,
  }));

  useEffect(() => {
    if (filteredItems.length === 0) {
      setSelectedItemKey('');
      return;
    }

    setSelectedItemKey(`${filteredItems[0].id}`);
  }, [command, filteredItems]);

  const getSelectedItemIndex = useLastCallback(() => {
    if (filteredItems.length === 0) {
      return -1;
    }

    return filteredItems.findIndex((item) => `${item.id}` === selectedItemKey);
  });

  const handleArrowKey = useLastCallback((value: number, e: KeyboardEvent) => {
    if (!isMenuOpen) {
      return;
    }

    e.preventDefault();
    const currentSelectedIndex = getSelectedItemIndex();
    if (currentSelectedIndex === undefined || currentSelectedIndex === -1) {
      setSelectedItemKey('');
      return;
    }

    const newSelectedIndex = currentSelectedIndex + value;

    const adjustedSelectedIndex =
      newSelectedIndex < 0
        ? filteredItems.length + newSelectedIndex
        : newSelectedIndex > filteredItems.length - 1
          ? newSelectedIndex - filteredItems.length
          : newSelectedIndex;

    setSelectedItemKey(`${filteredItems[adjustedSelectedIndex].id}`);
  });

  const handleItemSelect = useLastCallback((e: KeyboardEvent) => {
    if (!isMenuOpen) {
      return false;
    }

    const selectedIndex = getSelectedItemIndex();
    if (selectedIndex > -1) {
      const item = filteredItems?.[selectedIndex];
      if (item) {
        onTemplateSelected(item.template);
        e.preventDefault();
      }
    }

    return true;
  });

  useEffect(
    () =>
      captureKeyboardListeners({
        onUp: (e) => handleArrowKey(-1, e),
        onDown: (e) => handleArrowKey(1, e),
        onEnter: handleItemSelect,
        onEsc: () => isMenuOpen && onClose(),
      }),
    [handleArrowKey, handleItemSelect, onClose, isMenuOpen],
  );

  // Scroll menu items popover if needed
  useEffect(() => {
    const ref = menuRef.current;
    if (ref === null) {
      return;
    }

    const htmlList = ref.menu?.list;
    if (!htmlList) {
      return;
    }

    const selectedItem = htmlList.querySelector('.ant-menu-item-selected');
    if (selectedItem) {
      selectedItem.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [selectedItemKey]);

  const content = (
    <Menu
      className={styles.templatesMenu}
      onClick={(e) => {
        const template = templates.find((item) => item.id.toString() === e.key)?.template;
        if (template !== undefined) {
          onTemplateSelected(template);
          setIsHovered(false);
        }
      }}
      items={menuItems}
      selectedKeys={[selectedItemKey]}
      ref={menuRef}
    />
  );

  return (
    <Popover
      className={className}
      placement="topLeft"
      getPopupContainer={(node) => popupContainerRef.current || node}
      open={isMenuOpen && filteredItems.length > 0}
      onOpenChange={handleHoverChange}
      content={content}
    >
      <Button type="link" icon={<MenuOutlined />} />
    </Popover>
  );
};

export default MessageTemplatesPopover;
