import PasswordInputWithStrengthMeter from '@components/PasswordInputWithStrengthMeter';
import { Role } from '@interfaces/role';
import type { User } from '@interfaces/user';
import { Button, Form, Input, Modal, Select } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useFetcher } from 'react-router-dom';

const isErrorResponse = (response: Response | User): response is Response => {
  return 'ok' in response && !response.ok;
};

interface Values {
  username: string;
  full_name: string;
  password: string;
  role: string;
  tg_username: string | null;
}

interface OwnProps {
  open: boolean;
  onCreate: (user: User) => void;
  onCancel: () => void;
}

const UserCreateForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const { submit, state, data: response } = useFetcher<Response | User>();
  const [form] = Form.useForm<Values>();

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'username',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    form.resetFields();
    onCreate(response);
  }, [form, onCreate, response]);

  const onSubmit = useCallback(async () => {
    const values = await form.validateFields();
    submit(
      {
        intent: 'add',
        ...values,
      },
      {
        method: 'post',
        encType: 'application/json',
      },
    );
  }, [form, submit]);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      open={open}
      title="Add new user"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={state === 'submitting'} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={state === 'submitting'}
        initialValues={{ role: 'agent' }}
      >
        <Form.Item
          name="full_name"
          label="Full Name"
          rules={[
            {
              required: true,
              message: 'Please input the full name!',
            },
            {
              max: 64,
              message: 'Full name can not exceed 64 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 64 }} autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: 'Please input the username!',
            },
            {
              max: 32,
              message: 'Username can not exceed 32 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 32 }} autoComplete="off" />
        </Form.Item>
        <PasswordInputWithStrengthMeter
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input the password!',
            },
          ]}
          inputProps={{
            showStrengthMeter: true,
            validatePasswordLevel: 4,
            autoComplete: 'new-password',
          }}
        />
        <Form.Item
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: 'Please select the role!',
            },
          ]}
        >
          <Select
            options={Object.entries(Role).map(([key, value]) => ({
              label: key,
              value,
            }))}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
          {({ getFieldValue }) => (
            <Form.Item
              name="tg_username"
              label="TG Username"
              rules={[
                {
                  type: 'string',
                  pattern: /^[a-z0-9_]{5,32}$/,
                  message: 'Username must be at least five characters long and can contain a-z, 0–9, and underscores',
                },
              ]}
            >
              <Input
                disabled={getFieldValue('role') !== Role.Admin}
                count={{ show: true, max: 32 }}
                autoComplete="off"
              />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserCreateForm;
