import useAuth from '@hooks/use-auth';
import { useIntersectionObserver, useOnIntersect } from '@hooks/use-intersection-observer';
import useLastCallback from '@hooks/use-last-callback';
import type { ConversationCache } from '@store/types/conversation-cache';
import { loadViewportMessages } from '@utils/api/load-viewport-messages';
import { debounce } from '@utils/schedulers';
import type { RefObject } from 'react';
import { useEffect, useMemo, useRef } from 'react';

import { useSelector } from '@/store';

export type LoadMoreDirection = 'backwards' | 'forwards';

const FAB_THRESHOLD = 50;
const NOTCH_THRESHOLD = 1;

function useScroll(
  conversationId: number,
  containerRef: RefObject<HTMLDivElement>,
  hasUnread: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFabToggle: (...args: any[]) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNotchToggle: (...args: any[]) => void,
) {
  const messageIds = useSelector<ConversationCache, number[] | undefined>(
    `conversation/${conversationId}`,
    (state) => state?.messageIds,
  );
  const isReady = Boolean(messageIds);
  const auth = useAuth();
  const [loadMoreBackwards, loadMoreForwards] = useMemo(
    () => [
      debounce(() => loadViewportMessages(auth, 'before', conversationId), 1000, true, false),
      debounce(() => loadViewportMessages(auth, 'after', conversationId), 1000, true, false),
    ],
    [auth, conversationId],
  );

  const backwardsTriggerRef = useRef<HTMLDivElement>(null);
  const forwardsTriggerRef = useRef<HTMLDivElement>(null);
  const fabTriggerRef = useRef<HTMLDivElement>(null);

  const toggleScrollTools = useLastCallback(() => {
    if (!isReady) {
      return;
    }

    if (!messageIds?.length) {
      onFabToggle(false);
      onNotchToggle(false);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const container = containerRef.current!;
    if (!container) {
      return;
    }

    const { offsetHeight, scrollHeight, scrollTop } = container;
    const scrollBottom = Math.round(scrollHeight - scrollTop - offsetHeight);
    const isNearBottom = scrollBottom <= FAB_THRESHOLD;
    const isAtBottom = scrollBottom <= NOTCH_THRESHOLD;

    if (scrollHeight === 0) {
      return;
    }

    onFabToggle(hasUnread ? !isAtBottom : !isNearBottom);
    onNotchToggle(!isAtBottom);
  });

  const { observe: observeIntersection } = useIntersectionObserver(
    {
      rootRef: containerRef,
      margin: 200,
    },
    (entries) => {
      if (!loadMoreForwards || !loadMoreBackwards) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const container = containerRef.current!;

      entries.forEach(({ isIntersecting, target }) => {
        if (!isIntersecting) {
          return;
        }

        if (target.className === 'backwards-trigger' && container.scrollHeight > container.offsetHeight) {
          loadMoreBackwards();
        }

        if (target.className === 'forwards-trigger') {
          loadMoreForwards();
        }
      });
    },
  );

  const withHistoryTriggers = messageIds && messageIds.length > 1;

  useOnIntersect(backwardsTriggerRef, observeIntersection);
  useOnIntersect(forwardsTriggerRef, observeIntersection);

  const { observe: observeIntersectionForFab } = useIntersectionObserver(
    {
      rootRef: containerRef,
      margin: FAB_THRESHOLD * 2,
    },
    toggleScrollTools,
  );

  useOnIntersect(fabTriggerRef, observeIntersectionForFab);

  const { observe: observeIntersectionForNotch } = useIntersectionObserver(
    {
      rootRef: containerRef,
      margin: NOTCH_THRESHOLD,
    },
    toggleScrollTools,
  );

  useOnIntersect(fabTriggerRef, observeIntersectionForNotch);

  useEffect(() => {
    if (isReady) {
      toggleScrollTools();
    }
  }, [conversationId, isReady, toggleScrollTools]);

  return { withHistoryTriggers, backwardsTriggerRef, forwardsTriggerRef, fabTriggerRef };
}

export default useScroll;
