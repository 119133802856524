import type { Message } from '@interfaces/message';
import type { FocusDirection, FocusedMessage } from '@store/types/focused-message';
import type { InputMessageReplyInfo } from '@store/types/input-message-reply-info';

import { getStore } from '@/store';

export function updateFocusedMessage(conversationId: number, messageId?: number) {
  const store = getStore<FocusedMessage>('focusedMessage');
  store.setState((state) => ({
    ...state,
    conversationId,
    messageId,
  }));
}

export function updateFocusDirection(direction?: FocusDirection) {
  const store = getStore<FocusedMessage>('focusedMessage');
  store.setState((state) => ({
    ...state,
    direction,
  }));
}

export function setInputMessageReplyInfo(message: Message) {
  const store = getStore<InputMessageReplyInfo>('inputMessageReplyInfo');
  store.setState(() => ({
    message,
  }));
}

export function removeInputMessageReplyInfo() {
  const store = getStore<InputMessageReplyInfo>('inputMessageReplyInfo');
  store.setState(() => ({
    message: undefined,
  }));
}
