import type { AuthContextType } from '@context/auth.context';
import type { ConversationListItem } from '@interfaces/conversation';
import { apiRequest, isResponse } from '@services/fetcher';
import type { LoaderFunction } from 'react-router-dom';

export const loader = (auth: AuthContextType) =>
  (async ({ request }) => {
    const response = await apiRequest<ConversationListItem[]>(`${import.meta.env.VITE_API_URL}/conversation`, auth, {
      signal: request.signal,
    });

    if (isResponse(response)) {
      return {
        status: response.status,
        statusText: response.statusText,
      } as Response;
    }

    if (response instanceof Error) {
      throw response;
    }

    return response;
  }) satisfies LoaderFunction;
