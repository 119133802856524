import type { AuthContextType } from '@context/auth.context';
import fetchPaginatedUserMessages from '@utils/api/fetchUserMessages';
import type { LoaderFunction } from 'react-router-dom';

export const loader = (auth: AuthContextType) =>
  (async ({ request }) => {
    return await fetchPaginatedUserMessages(auth, 1, 10, {
      signal: request.signal,
    });
  }) satisfies LoaderFunction;
