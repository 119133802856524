import type { AuthContextType } from '@context/auth.context';
import { ConflictError } from '@services/types/conflict-error';
import { ValidationError } from '@services/types/validation-error';
import { getReasonPhrase } from 'http-status-codes';
import { redirect } from 'react-router-dom';

export const isResponse = <T>(response: Response | T): response is Response => {
  return response !== null && typeof response === 'object' && 'status' in response;
};

export const apiRequest = async <T = unknown>(
  url: string,
  auth: AuthContextType,
  options?: RequestInit & { plain?: boolean },
) => {
  if (options === undefined) {
    options = {
      headers: addHeaderIfNeeded('Authorization', getAuthHeader(url, auth)['Authorization']),
    };
  } else {
    options.headers = addHeaderIfNeeded('Authorization', getAuthHeader(url, auth)['Authorization'], options.headers);
  }

  if (options.body && options.body instanceof FormData) {
    options.headers = addHeaderIfNeeded('Content-Type', 'application/json', options.headers);
    // Convert to JSON and convert empty values to null
    options.body = JSON.stringify(
      Object.fromEntries([...options.body.entries()].map(([key, value]) => [key, value === '' ? null : value])),
    );
  } else if (typeof options.body === 'string') {
    // We have received JSON, set headers and send as is
    options.headers = addHeaderIfNeeded('Content-Type', 'application/json', options.headers);
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        if (url.endsWith('login')) {
          return response;
        }

        // auto logout
        auth.signOut();
        return redirect('/login');
      } else {
        const responseAsJson = await response.json();
        if (response.status === 400) {
          // noinspection ExceptionCaughtLocallyJS
          throw new ValidationError(responseAsJson.message, responseAsJson.details);
        }

        if (response.status === 409) {
          // noinspection ExceptionCaughtLocallyJS
          throw new ConflictError(responseAsJson.message);
        }

        // noinspection ExceptionCaughtLocallyJS
        throw new Error(getReasonPhrase(response.status));
      }
    }

    if (!response.headers.get('content-type')?.includes('application/json') || options.plain) {
      return response;
    }

    return (await response.json()) as T;
  } catch (error) {
    // do something on error
    return error as Error;
  }
};

function addHeaderIfNeeded(key: string, value: string, headers?: HeadersInit): HeadersInit {
  if (headers === undefined) {
    headers = {
      [key]: value,
    };
  } else if (headers instanceof Headers) {
    !headers.has(key) && headers.set(key, value);
  } else if (!(key in headers)) {
    headers = {
      ...headers,
      [key]: value,
    };
  }

  return headers;
}

function getAuthHeader(url: string, auth: AuthContextType): Record<string, string> {
  const token = auth.getToken();
  const isAuthenticated = !!token;
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);
  if (isAuthenticated && isApiUrl) {
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
}
