import type { AuthContextType } from '@context/auth.context';
import type { Message } from '@interfaces/message';
import type { ConversationCache } from '@store/types/conversation-cache';
import type { LoadDirection } from '@utils/api/fetchCursorMessages';
import fetchCursorMessages from '@utils/api/fetchCursorMessages';

import { getStore } from '@/store';

export const loadViewportMessages = async (
  auth: AuthContextType,
  direction: LoadDirection,
  conversationId: number,
  cursor?: number,
  limit: number | null = 60,
) => {
  const store = getStore<ConversationCache>(`conversation/${conversationId}`);
  if (store.state?.messageIds && direction === 'before' && store.state.previousCursor === undefined) {
    return;
  }

  if (store.state?.messageIds && direction === 'after' && store.state.nextCursor === undefined) {
    return;
  }

  if (store.state?.messageIds && direction === 'both') {
    return;
  }

  switch (direction) {
    case 'both': {
      const response = await fetchCursorMessages(auth, conversationId, cursor || null, 'both', limit);
      const messageIds = response.data.map((message) => message.id);
      // TODO: remove logging
      console.log(`Populating store for the conversation ${conversationId}.`);

      store.setState(() => ({
        id: conversationId,
        messageIds: messageIds,
        messagesById: response.data.reduce(
          (acc, message) => {
            acc[message.id] = message;
            return acc;
          },
          {} as Record<number, Message>,
        ),
        lastMessage: response.data?.[response.data.length - 1],
        lastReadId: cursor,
        previousCursor: response.meta?.previous_cursor,
        nextCursor: response.meta?.next_cursor,
      }));
      break;
    }
    case 'before': {
      const response = await fetchCursorMessages(
        auth,
        conversationId,
        store.state.previousCursor || null,
        'before',
        limit,
      );
      store.setState((state) => ({
        ...state,
        messageIds: response.data.map((message) => message.id).concat(state.messageIds || []),
        messagesById: {
          ...response.data.reduce(
            (acc, message) => {
              acc[message.id] = message;
              return acc;
            },
            {} as Record<number, Message>,
          ),
          ...state.messagesById,
        },
        previousCursor: response.meta?.previous_cursor,
      }));
      break;
    }
    case 'after': {
      const response = await fetchCursorMessages(auth, conversationId, store.state.nextCursor || null, 'after', limit);
      const currentMessagesById = Object.keys(store.state?.messagesById || {}).reduce(
        (acc, key) => {
          const message = store.state.messagesById![Number(key)];
          if (message.id) {
            acc[message.id] = message;
          } else {
            store.state.messageIds = store.state.messageIds!.filter((id) => id !== Number(key));
          }
          return acc;
        },
        {} as Record<number, Message>,
      );
      store.setState((state) => ({
        ...state,
        messageIds: (state.messageIds || []).concat(
          response.data.filter((message) => message.id !== state.lastMessage?.id).map((message) => message.id),
        ),
        messagesById: {
          ...currentMessagesById,
          ...response.data.reduce(
            (acc, message) => {
              if (message.id === state.lastMessage?.id) {
                return acc;
              }
              acc[message.id] = message;
              return acc;
            },
            {} as Record<number, Message>,
          ),
        },
        lastMessage: response.data?.[response.data.length - 1],
        nextCursor: response.meta?.next_cursor,
      }));
      break;
    }
  }
};
