import type { AuthContextType } from '@context/auth.context';
import type { MessageTemplate } from '@interfaces/message-template';
import { apiRequest, isResponse } from '@services/fetcher';

import { getStore } from '@/store';

export const loadMessageTemplates = async (auth: AuthContextType) => {
  const store = getStore<MessageTemplate[]>('message-templates');
  const response = await apiRequest<MessageTemplate[]>(
    `${import.meta.env.VITE_API_URL}/organization/message-template`,
    auth,
  );

  if (isResponse(response)) {
    throw new Error('Unexpected response');
  }

  if (response instanceof Error) {
    throw response;
  }

  store.setState(response);
};
