import type { AuthContextType } from '@context/auth.context';
import type { TelegramUser } from '@interfaces/telegram-user';
import { apiRequest, isResponse } from '@services/fetcher';

async function fetchConversationTelegramUsers(auth: AuthContextType, conversationId: number, options?: RequestInit) {
  const url = `${import.meta.env.VITE_API_URL}/conversation/${conversationId}/telegram-users`;
  const response = await apiRequest<TelegramUser[]>(url, auth, options);

  if (isResponse(response)) {
    return {
      status: response.status,
      statusText: response.statusText,
    } as Response;
  }

  if (response instanceof Error) {
    throw response;
  }

  return response;
}

export default fetchConversationTelegramUsers;
