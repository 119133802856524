import type { AuthContextType } from '@context/auth.context';
import type { User } from '@interfaces/user';
import { apiRequest, isResponse } from '@services/fetcher';
import { ConflictError } from '@services/types/conflict-error';
import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const data = await request.json();
    const intent = data.intent;
    delete data.intent;

    if (intent === 'add') {
      const response = await apiRequest<User>(`${import.meta.env.VITE_API_URL}/manage/user`, auth, {
        method: request.method,
        signal: request.signal,
        headers: request.headers,
        body: JSON.stringify(data),
      });

      if (response instanceof ConflictError) {
        return json({ ok: false, statusText: response.message });
      }

      if (response instanceof Error) {
        throw response;
      }

      if (isResponse(response)) {
        return {
          status: response.status,
          statusText: response.statusText,
        } as Response;
      }

      return response;
    } else if (intent === 'update') {
      const userId = data.id;
      delete data.id;
      const response = await apiRequest<User>(`${import.meta.env.VITE_API_URL}/manage/user/${userId}`, auth, {
        method: request.method,
        signal: request.signal,
        headers: request.headers,
        body: JSON.stringify(data),
      });

      if (response instanceof ConflictError) {
        return json({ ok: false, statusText: response.message });
      }

      if (response instanceof Error) {
        throw response;
      }

      return response;
    } else {
      const userId = data.id;
      delete data.id;
      const response = await apiRequest<Response>(`${import.meta.env.VITE_API_URL}/manage/user/${userId}`, auth, {
        method: request.method,
        signal: request.signal,
        headers: request.headers,
      });

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    }
  }) satisfies ActionFunction;
