import type { CheckboxProps, SelectProps, SwitchProps, TimeRangePickerProps } from 'antd';
import { Checkbox, Col, Row, Select, Switch, TimePicker } from 'antd';
import dayjs from 'dayjs';
import Timezone from 'dayjs/plugin/timezone';
import Utc from 'dayjs/plugin/utc';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import styles from './WorkHours.module.scss';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(Utc);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(Timezone);

const WeekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;
type WeekdayName = (typeof WeekdayNames)[number];
type Hour = `${0 | 1}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}` | `2${0 | 1 | 2 | 3}`;
type Minute = `${0 | 1 | 2 | 3 | 4 | 5}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`;
type TimeString = `${Hour}:${Minute}`;
type WorkHoursRange = readonly [start: TimeString | null, end: TimeString | null];
type WorkHourPerDayOfWeek = Record<WeekdayName, WorkHoursRange | undefined>;
export type WorkHoursValues = WorkHourPerDayOfWeek & {
  TZ: string;
};

// Grouped formatted IANA timezones
const timezoneOptions: SelectProps['options'] = Object.entries(
  Intl.supportedValuesOf('timeZone')
    .map((timezone) => ({ value: timezone, label: timezone.split('/')[1].replace(/_/g, ' ') }))
    .reduce(
      (acc, value) => {
        (acc[value.value.split('/')[0]] ||= []).push(value);
        return acc;
      },
      {} as Record<string, { value: string; label: string }[]>,
    ),
).map(([key, value]) => ({ label: key, title: key, options: value }));

export interface OwnProps {
  id?: string;
  'aria-describedby'?: string;
  'aria-required'?: string;
  'aria-invalid'?: string;
  value?: WorkHoursValues;
  onChange?: (value?: WorkHoursValues) => void;
}

const WorkHours: FC<OwnProps> = ({ id, 'aria-describedby': aDescribedBy, value, onChange }) => {
  const weekDays = WeekdayNames;

  const parseToInternalValue = (value?: WorkHoursValues) => {
    const valueWithDefaults: WorkHoursValues = value || {
      Sunday: undefined,
      Monday: undefined,
      Tuesday: undefined,
      Wednesday: undefined,
      Thursday: undefined,
      Friday: undefined,
      Saturday: undefined,
      TZ: dayjs.tz.guess(),
    };

    // Validate timezone if provided
    if (value?.TZ && !Intl.supportedValuesOf('timeZone').includes(value.TZ)) {
      valueWithDefaults.TZ = dayjs.tz.guess();
    }

    return valueWithDefaults;
  };

  const [internalValue, setInternalValue] = useState<WorkHoursValues>(parseToInternalValue(value));
  useEffect(() => {
    setInternalValue(parseToInternalValue(value));
  }, [value]);

  const isDayOpen = (weekDay: WeekdayName) => internalValue[weekDay] !== undefined;
  const is24HoursOpen = (weekDay: WeekdayName) =>
    internalValue[weekDay] !== undefined && internalValue[weekDay][0] === null && internalValue[weekDay][1] === null;
  const timeToDayJS = (time?: TimeString | null) => (time ? dayjs(time, 'HH:mm') : undefined);
  const onChange24Hours =
    (weekDay: WeekdayName): CheckboxProps['onChange'] =>
    (event) => {
      const newValue = { ...internalValue, [weekDay]: event.target.checked ? [null, null] : ['00:00', '23:59'] };
      setInternalValue(newValue);
      onChange?.(newValue);
    };
  const onChangeDayOpenClosed =
    (weekDay: WeekdayName): SwitchProps['onChange'] =>
    (open) => {
      const newValue = { ...internalValue, [weekDay]: open ? [null, null] : undefined };
      setInternalValue(newValue);
      onChange?.(newValue);
    };
  const onTimeChange =
    (weekDay: WeekdayName): TimeRangePickerProps['onChange'] =>
    (range) => {
      const newValue = {
        ...internalValue,
        [weekDay]: range && range[0] && range[1] ? [range[0].format('HH:mm'), range[1].format('HH:mm')] : undefined,
      };
      setInternalValue(newValue);
      onChange?.(newValue);
    };
  const onTimeZoneChange = (newTZ: string) => {
    const newValue = { ...internalValue, TZ: newTZ };
    setInternalValue(newValue);
    onChange?.(newValue);
  };

  const workingHoursRenderer = (weekDay: WeekdayName) => {
    return (
      <Row key={weekDay} className={styles.workHoursRow} role="group" aria-labelledby={`${weekDay}_label`}>
        <Col span={4}>
          <label id={`${weekDay}_label`} htmlFor={`${weekDay}_switch`}>
            {weekDay}
          </label>
        </Col>
        <Col span={4}>
          <Switch
            id={`${weekDay}_switch`}
            checkedChildren="Open"
            unCheckedChildren="Closed"
            checked={isDayOpen(weekDay)}
            onChange={onChangeDayOpenClosed(weekDay)}
          />
        </Col>
        <Col span={4}>
          <Checkbox
            checked={is24HoursOpen(weekDay)}
            onChange={onChange24Hours(weekDay)}
            style={{ verticalAlign: 'middle' }}
          >
            24 hours
          </Checkbox>
        </Col>
        <Col span={12}>
          {isDayOpen(weekDay) && !is24HoursOpen(weekDay) && (
            <TimePicker.RangePicker
              format="HH:mm"
              minuteStep={15}
              allowClear={false}
              allowEmpty={false}
              value={[timeToDayJS(internalValue?.[weekDay]?.[0]), timeToDayJS(internalValue?.[weekDay]?.[1])]}
              onChange={onTimeChange(weekDay)}
              order={true}
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div id={id} aria-describedby={aDescribedBy}>
      {weekDays.map((weekDay) => workingHoursRenderer(weekDay))}
      <Row key="timezone" className={styles.timezoneSelector}>
        <Col span={4}>
          <label htmlFor="timezone_select">Timezone:</label>
        </Col>
        <Col span={20}>
          <Select
            id="timezone_select"
            options={timezoneOptions}
            showSearch
            value={internalValue.TZ}
            onChange={onTimeZoneChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default WorkHours;
