import { PhoneOutlined } from '@ant-design/icons';
import type { ErrorHandler } from '@context/socket.context';
import useAuth from '@hooks/use-auth';
import useSocket from '@hooks/use-socket';
import type { TelegramUser } from '@interfaces/telegram-user';
import type { loader as conversationLoader } from '@pages/ChatFeed/RightColumn';
import { isResponse } from '@services/fetcher';
import type { LoaderData } from '@services/types/loader-data';
import fetchConversationTelegramUsers from '@utils/api/fetchConversationTelegramUsers';
import { loadMessageTemplates } from '@utils/api/load-message-templates';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Modal } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useRouteLoaderData } from 'react-router-dom';

import styles from './ChatFeed.module.scss';
import ConversationList from './ConversationList';

const ChatFeed: FC = () => {
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const socket = useSocket();
  const auth = useAuth();
  const userId = auth.getUserId();
  const [modal, contextHolder] = Modal.useModal();
  const [chatTitle, setChatTitle] = useState<string>();
  const selectedConversation = useRouteLoaderData('conversation') as LoaderData<typeof conversationLoader> | undefined;
  const [conversationUsers, setConversationUsers] = useState<TelegramUser[]>();

  useEffect(() => {
    if (selectedConversation) {
      setChatTitle(selectedConversation.name);
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (selectedConversation?.id) {
      fetchConversationTelegramUsers(auth, selectedConversation.id).then((response) => {
        if (isResponse(response)) {
          // Do we have a case for Response object to handle?
          return;
        }

        setConversationUsers(response);
      });
    }
  }, [auth, selectedConversation?.id]);

  useEffect(() => {
    const errorHandler: ErrorHandler = (exception) => {
      modal.error({
        type: 'error',
        title: 'Error',
        content: exception.message,
      });
    };

    socket.on('exception', errorHandler);

    return () => {
      socket.off('exception', errorHandler);
    };
  }, [modal, socket]);

  useEffect(() => {
    if (userId === undefined) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    loadMessageTemplates(auth);
  }, [userId, auth]);

  const usersWithPhoneNumber = (conversationUsers || []).filter((user) => user.phone_number !== null);

  const items: MenuProps['items'] = usersWithPhoneNumber.map((user) => ({
    key: user.id,
    label: (
      <a target="_blank" rel="noopener noreferrer" href={`tel:${user.phone_number}`} title={`Call ${user.full_name}`}>
        {user.full_name}
      </a>
    ),
  }));

  return (
    <div className={styles.chatFeed}>
      <div className={`${styles.sidebar} custom-scroll`}>
        <ConversationList />
      </div>
      <div className={styles.mainContent}>
        {chatTitle && (
          <div className={styles.chatHeader}>
            <h1 className={styles.chatTitle}>{chatTitle}</h1>
            {usersWithPhoneNumber.length > 1 && (
              <Dropdown menu={{ items }}>
                <Button size="small">
                  Call <PhoneOutlined />
                </Button>
              </Dropdown>
            )}
            {usersWithPhoneNumber.length == 1 && (
              <Button size="small" href={`tel:${usersWithPhoneNumber?.[0]?.phone_number}`}>
                <PhoneOutlined /> Call {usersWithPhoneNumber?.[0]?.full_name}
              </Button>
            )}
          </div>
        )}
        {contextHolder}
        <Outlet />
      </div>
    </div>
  );
};

export default ChatFeed;
