import useAuth from '@hooks/use-auth';
import type { Role } from '@interfaces/role';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

interface OwnProps {
  allowedRoles: Role[];
}

const RequireAuth: FC<OwnProps> = ({ allowedRoles }) => {
  const { hasRole } = useAuth();

  return allowedRoles.some((role) => hasRole(role)) ? <Outlet /> : null;
};

export default RequireAuth;
