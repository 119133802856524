import type { AuthContextType } from '@context/auth.context';
import type { TypedSocket } from '@context/socket.context';
import type { LocalMessage } from '@interfaces/message';
import type { ConversationCache } from '@store/types/conversation-cache';
import { loadViewportMessages } from '@utils/api/load-viewport-messages';

import { getStore } from '@/store';

export async function sendMessage(
  auth: AuthContextType,
  socket: TypedSocket,
  conversationId: number,
  text: string,
  userId: number,
  authorName?: string,
  replyToId?: number,
) {
  const store = getStore<ConversationCache>(`conversation/${conversationId}`);

  // TODO: remove logging
  console.log(`Sending local message to ${conversationId}.`);

  if (store.state.nextCursor) {
    // TODO: remove logging
    console.log(`Need to load new messages before appending local message.`);
    await loadViewportMessages(auth, 'after', conversationId, store.state.nextCursor, null);
  }

  const lastMessageId = store.state?.lastMessage?.id || store.state?.lastMessage?.localId;
  // TODO: remove logging
  console.log(`Previous message id: ${lastMessageId}.`);

  const newLocalId = (lastMessageId || 0) + 1;
  const localMessage: LocalMessage = {
    localId: newLocalId,
    type: 'text',
    user_id: userId,
    telegram_user_id: null,
    author_name: authorName !== undefined ? authorName : null,
    author_phone: null,
    body: text,
    created_at: new Date().toISOString(),
    extra_attributes: {},
    reply_to_id: replyToId,
  };

  // TODO: remove logging
  console.log(`Appending new local message.`, localMessage);
  store.setState((state) => ({
    ...state,
    messageIds: state.messageIds?.concat(newLocalId),
    messagesById: {
      ...(state.messagesById || {}),
      [`${newLocalId}`]: localMessage,
    },
    lastMessage: localMessage,
    lastReadId: newLocalId,
  }));

  // TODO: remove logging
  console.log(`Emitting local message (${newLocalId}) to backend.`);
  socket.emit('message', {
    conversationId: Number(conversationId),
    message: localMessage,
  });
}
