import { LoadingOutlined } from '@ant-design/icons';
import useLastCallback from '@hooks/use-last-callback';
import useMedia from '@hooks/use-media';
import type { ApiVideo, ApiVideoNote } from '@interfaces/messages';
import { isPlayableVideo } from '@interfaces/messages';
import { Spin } from 'antd';
import type { CSSProperties, FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './Video.module.scss';

// const MAX_AVAILABLE_WIDTH = 640;
const MAX_AVAILABLE_HEIGHT = 480;
const SAFE_WIDTH_INSET = 32;

function calculateDimensions(width: number, height: number, container: HTMLDivElement) {
  const aspectRatio = height / width;
  const availableWidth =
    parseFloat(getComputedStyle(container).fontSize) *
      parseFloat(getComputedStyle(container).getPropertyValue('--max-width')) -
    SAFE_WIDTH_INSET;
  const calculatedWidth = Math.min(width, availableWidth);
  const calculatedHeight = Math.round(calculatedWidth * aspectRatio);

  if (calculatedHeight > MAX_AVAILABLE_HEIGHT) {
    return {
      width: Math.round(MAX_AVAILABLE_HEIGHT / aspectRatio),
      height: MAX_AVAILABLE_HEIGHT,
    };
  }

  return {
    width: calculatedWidth,
    height: Math.round(calculatedWidth * aspectRatio),
  };
}

const isVideoNote = (video: ApiVideo | ApiVideoNote): video is ApiVideoNote => 'length' in video;

interface OwnProps {
  id: number;
  video: ApiVideo | ApiVideoNote;
}

const Video: FC<OwnProps> = ({ id, video }) => {
  const [shouldSpinnerRender, setShouldSpinnerRender] = useState(true);
  const [calculatedDimensions, setCalculatedDimensions] = useState<{
    calculatedWidth?: number;
    calculatedHeight?: number;
  }>({
    calculatedWidth: undefined,
    calculatedHeight: undefined,
  });
  const playerRef = useRef<HTMLVideoElement>(null);
  const thumbnailUrl = useMedia(id, video.thumbnail !== undefined, video.thumbnail?.file_id);
  const videoUrl = useMedia(id, true);

  const videoWidth = isVideoNote(video) ? video.length : video.width;
  const videoHeight = isVideoNote(video) ? video.length : video.height;

  const isPlayable = isPlayableVideo(video.mime_type || 'video/mp4');

  useEffect(() => {
    setShouldSpinnerRender(videoUrl === undefined);
  }, [videoUrl]);

  const widthStyles: CSSProperties = video.thumbnail
    ? {
        minWidth: video.thumbnail.width,
        minHeight: video.thumbnail.height,
      }
    : {};
  const style: CSSProperties | undefined =
    videoUrl && calculatedDimensions.calculatedHeight
      ? {
          ...widthStyles,
          height: `${calculatedDimensions.calculatedHeight}px`,
        }
      : widthStyles;

  const measureDimensionsRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        const { width: calculatedWidth, height: calculatedHeight } = calculateDimensions(videoWidth, videoHeight, node);
        setCalculatedDimensions({
          calculatedWidth,
          calculatedHeight,
        });
      }
    },
    [videoWidth, videoHeight],
  );

  const areVideoDimensionsCalculated =
    calculatedDimensions.calculatedWidth !== undefined && calculatedDimensions.calculatedHeight !== undefined;
  const sourceType = (video.mime_type as string | undefined) || 'video/mp4';
  const fileName = videoUrl ? (video.file_name as string | undefined) || 'VideoMessage' : undefined;

  const togglePlaying = useLastCallback(() => {
    const playerElement = playerRef.current;
    if (!playerElement) {
      return;
    }

    if (playerElement.paused) {
      // noinspection JSIgnoredPromiseFromCall
      playerElement.play();
    } else {
      playerElement.pause();
    }
  });

  return (
    <Spin indicator={<LoadingOutlined spin />} spinning={shouldSpinnerRender}>
      {isPlayable ? (
        <div ref={measureDimensionsRef} className="media-inner" style={style}>
          {areVideoDimensionsCalculated && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              ref={playerRef}
              controls={!isVideoNote(video)}
              disablePictureInPicture
              playsInline
              poster={thumbnailUrl}
              width={calculatedDimensions.calculatedWidth}
              height={calculatedDimensions.calculatedHeight}
              style={
                isVideoNote(video) && calculatedDimensions.calculatedWidth
                  ? { borderRadius: calculatedDimensions.calculatedWidth / 2 }
                  : {}
              }
              className={isVideoNote(video) ? styles.roundVideo : undefined}
              onClick={isVideoNote(video) ? togglePlaying : undefined}
            >
              {videoUrl && (
                <>
                  <source src={videoUrl} type={sourceType} />
                  Download the <a href={videoUrl}>{fileName || 'VideoMessage'}</a> video.
                </>
              )}
            </video>
          )}
        </div>
      ) : (
        <span>
          This video format can not be played. You can{' '}
          <a href={videoUrl} download={fileName || 'VideoMessage'}>
            download it here.
          </a>
        </span>
      )}
    </Spin>
  );
};

export default Video;
