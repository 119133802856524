import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import useFormResponse from '@hooks/use-form-response';
import { languages } from '@interfaces/language';
import type { MessageTemplate, MessageTemplateTranslation } from '@interfaces/message-template';
import EditableCell from '@pages/Management/EditableCell';
import type { EditableCellProps } from '@pages/Management/EditableCell/EditableCell';
import TemplateAddForm from '@pages/Management/MessageTemplateManagement/TemplateAddForm';
import TemplateAddTranslationForm from '@pages/Management/MessageTemplateManagement/TemplateAddTranslationForm';
import type { LoaderData } from '@services/types/loader-data';
import { Alert, Button, Form, Popconfirm, Space, Table } from 'antd';
import type { ExpandedRowRender } from 'rc-table/lib/interface';
import type { FC, Key } from 'react';
import { useCallback, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';

import type { loader } from './loader';
import styles from './MessageTemplateManagement.module.scss';

interface TemplateForm {
  slug: string;
}

interface TemplateTranslationForm {
  template: string;
  language_code: string;
}

const MessageTemplateManagement: FC = () => {
  const templates = useLoaderData() as LoaderData<typeof loader>;
  const { submit, state, data: response } = useFetcher<Response | MessageTemplate>({ key: 'template-fetcher' });
  const {
    submit: translationFormSubmit,
    state: translationFormState,
    data: translationFormResponse,
  } = useFetcher<Response | MessageTemplateTranslation>({ key: 'translation-fetcher' });
  const [isTemplateAddModalOpen, setTemplateAddModalOpen] = useState(false);
  const [isTemplateTranslationAddModalOpen, setTemplateTranslationAddModalOpen] = useState(false);
  const [addTemplateTranslationId, setAddTemplateTranslationId] = useState(0);
  const [addTemplateTranslationLanguages, setAddTemplateTranslationLanguages] = useState(languages);
  const [form] = Form.useForm<TemplateForm>();
  const [translationForm] = Form.useForm<TemplateTranslationForm>();
  const [templateEditingKey, setTemplateEditingKey] = useState<number>();
  const [translationEditingKey, setTranslationEditingKey] = useState<{ template_id: number; language_code: string }>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);

  const isEditingTemplate = (record: MessageTemplate) => record.id === templateEditingKey;

  const isEditingTranslation = (record: MessageTemplateTranslation) =>
    record.template_id === translationEditingKey?.template_id &&
    record.language_code === translationEditingKey?.language_code;

  const resetEditing = useCallback(() => {
    setTemplateEditingKey(undefined);
    setTranslationEditingKey(undefined);
  }, []);

  const [formError, setFormError] = useFormResponse(form, response, true, resetEditing);
  const [translationFormError, setTranslationFormError] = useFormResponse(
    translationForm,
    translationFormResponse,
    true,
    resetEditing,
  );

  const onSave = useCallback(
    async (id: number) => {
      try {
        const values = await form.validateFields();
        const initialTemplate = templates?.find((template) => template.id === id);
        if (initialTemplate === undefined) {
          return;
        }

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => {
              return initialTemplate[key as keyof MessageTemplate] !== value;
            })
            .map(([key, value]) => {
              return [key, value === undefined ? '' : value];
            }),
        );

        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update-template',
              id: `${id}`,
              ...changedValues,
            },
            {
              method: 'patch',
              encType: 'application/json',
            },
          );
        } else {
          setTemplateEditingKey(undefined);
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [templates, form, submit],
  );

  const onSaveTranslation = useCallback(
    async (id: number, language_code: string) => {
      try {
        const values = await translationForm.validateFields();
        const initialTranslation = templates
          ?.find((template) => template.id === id)
          ?.translations?.find((translation) => translation.language_code === language_code);
        if (initialTranslation === undefined) {
          return;
        }

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => {
              return initialTranslation[key as keyof MessageTemplateTranslation] !== value;
            })
            .map(([key, value]) => {
              return [key, value === undefined ? '' : value];
            }),
        );

        if (Object.keys(changedValues).length > 0) {
          translationFormSubmit(
            {
              intent: 'update-translation',
              template_id: `${id}`,
              language_code_previous: language_code,
              ...changedValues,
            },
            {
              method: 'patch',
              encType: 'application/json',
            },
          );
        } else {
          setTranslationEditingKey(undefined);
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [templates, translationFormSubmit, translationForm],
  );

  const onTriggerCreateTemplate = useCallback((template: Omit<MessageTemplate, 'translations'>) => {
    setExpandedRowKeys([template.id]);
    setTemplateAddModalOpen(false);
  }, []);

  const onTriggerCreateTemplateTranslation = useCallback((translation: MessageTemplateTranslation) => {
    setExpandedRowKeys([translation.template_id]);
    setTemplateTranslationAddModalOpen(false);
  }, []);

  const expandedRowRender: ExpandedRowRender<MessageTemplate> = (record) => {
    const translations = record.translations;
    const existingLanguages = translations.map((translation) => translation.language_code);

    return (
      <Form form={translationForm} component={false}>
        <Table<MessageTemplateTranslation>
          dataSource={translations}
          rowKey={(record) => `${record.template_id}_${record.language_code}`}
          pagination={false}
          rowClassName="editable-row"
          loading={
            state === 'submitting' ||
            state === 'loading' ||
            translationFormState === 'submitting' ||
            translationFormState === 'loading'
          }
          components={{
            body: { cell: EditableCell },
          }}
        >
          <Table.Column<MessageTemplateTranslation>
            key="template"
            dataIndex="template"
            title="Text"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'template',
              inputType: 'textarea',
              title: 'Text',
              editing: isEditingTranslation(record),
              required: true,
              customRules: [
                {
                  pattern: /\S/,
                  message: 'At least one character other than space is required!',
                },
              ],
            })}
          />
          <Table.Column<MessageTemplateTranslation>
            key="language_code"
            dataIndex="language_code"
            title="Language"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'language_code',
              inputType: 'select',
              selectOptions: Object.entries(languages)
                .filter(([key]) => key === record.language_code || !existingLanguages.includes(key))
                .map(([key, value]) => ({
                  value: key,
                  label: value,
                })),
              title: 'Language',
              editing: record.language_code !== 'en' && isEditingTranslation(record),
              required: true,
            })}
            render={(value: string) => {
              return value in languages ? languages[value] : value;
            }}
          />
          <Table.Column<MessageTemplateTranslation>
            key="actions"
            title="Actions"
            render={(_, record) => {
              const editable = isEditingTranslation(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        title="Save"
                        onClick={() => onSaveTranslation(record.template_id, record.language_code)}
                      />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={() => {
                          setTranslationEditingKey(undefined);
                          setFormError(undefined);
                          setTranslationFormError(undefined);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          translationForm.setFieldsValue({
                            template: record.template,
                            language_code: record.language_code,
                          });
                          setTranslationEditingKey({
                            template_id: record.template_id,
                            language_code: record.language_code,
                          });
                        }}
                      />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => {
                          submit(
                            {
                              intent: 'delete-translation',
                              template_id: `${record.template_id}`,
                              language_code: `${record.language_code}`,
                            },
                            {
                              method: 'delete',
                              encType: 'application/json',
                            },
                          );
                        }}
                        disabled={record.language_code === 'en'}
                      >
                        <Button danger icon={<DeleteOutlined />} disabled={record.language_code === 'en'} />
                      </Popconfirm>{' '}
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    );
  };

  return (
    <>
      <div className={styles.buttons}>
        <Button
          type="primary"
          onClick={() => {
            setTemplateAddModalOpen(true);
          }}
          disabled={
            state === 'submitting' ||
            state === 'loading' ||
            translationFormState === 'submitting' ||
            translationFormState === 'loading'
          }
        >
          Add Shortcut
        </Button>
      </div>
      {isTemplateAddModalOpen && (
        <TemplateAddForm
          open={isTemplateAddModalOpen}
          onCreate={onTriggerCreateTemplate}
          onCancel={() => {
            setTemplateAddModalOpen(false);
          }}
        />
      )}
      {isTemplateTranslationAddModalOpen && (
        <TemplateAddTranslationForm
          open={isTemplateTranslationAddModalOpen}
          onCreate={onTriggerCreateTemplateTranslation}
          onCancel={() => {
            setTemplateTranslationAddModalOpen(false);
          }}
          template_id={addTemplateTranslationId}
          languages={addTemplateTranslationLanguages}
        />
      )}
      <Form form={form} component={false}>
        {(formError || translationFormError) && (
          <Alert
            message={formError || translationFormError}
            style={{ margin: '24px 0' }}
            type="error"
            closable
            afterClose={() => {
              setFormError(undefined);
              setTranslationFormError(undefined);
            }}
          />
        )}
        <Table<MessageTemplate>
          dataSource={templates}
          rowKey="id"
          loading={
            state === 'submitting' ||
            state === 'loading' ||
            translationFormState === 'submitting' ||
            translationFormState === 'loading'
          }
          rowClassName="editable-row"
          components={{
            body: { cell: EditableCell },
          }}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: [],
            expandedRowKeys,
            onExpandedRowsChange: (expandedKeys) => {
              setExpandedRowKeys(expandedKeys);
            },
            expandRowByClick: true,
          }}
          pagination={false}
        >
          <Table.Column<MessageTemplate> key="id" title="ID" dataIndex="id" />
          <Table.Column<MessageTemplate>
            key="slug"
            title="Shortcut"
            dataIndex="slug"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'slug',
              inputType: 'text',
              title: 'Shortcut',
              editing: isEditingTemplate(record),
              required: true,
              max: 128,
              customRules: [
                {
                  pattern: /^[\w-]+$/,
                  message: 'The shortcut can only contain Latin letters, numbers, dashes and underscores.',
                },
              ],
            })}
          />
          <Table.Column<MessageTemplate>
            key="actions"
            title="Actions"
            render={(_, record) => {
              const editable = isEditingTemplate(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        title="Save"
                        onClick={(event) => {
                          event.stopPropagation();
                          // noinspection JSIgnoredPromiseFromCall
                          onSave(record.id);
                        }}
                      />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={(event) => {
                          event.stopPropagation();
                          setTemplateEditingKey(undefined);
                          setFormError(undefined);
                          setTranslationFormError(undefined);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        title="Add Translation"
                        onClick={(event) => {
                          event.stopPropagation();
                          setAddTemplateTranslationId(record.id);
                          setAddTemplateTranslationLanguages(
                            Object.fromEntries(
                              Object.entries(languages).filter(
                                ([key]) =>
                                  !record.translations.map((translation) => translation.language_code).includes(key),
                              ),
                            ),
                          );
                          setTemplateTranslationAddModalOpen(true);
                        }}
                      />
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        title="Edit"
                        onClick={(event) => {
                          event.stopPropagation();
                          form.setFieldsValue({
                            slug: record.slug,
                          });
                          setTemplateEditingKey(record.id);
                        }}
                      />
                      <Popconfirm
                        title="Sure to delete template with all of it's translations?"
                        onConfirm={(event) => {
                          event?.stopPropagation();
                          submit(
                            {
                              intent: 'delete-template',
                              id: `${record.id}`,
                            },
                            {
                              method: 'delete',
                              encType: 'application/json',
                            },
                          );
                        }}
                        onCancel={(event) => event?.stopPropagation()}
                      >
                        <Button
                          danger
                          title="Delete"
                          icon={<DeleteOutlined />}
                          onClick={(event) => event.stopPropagation()}
                        />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </>
  );
};

export default MessageTemplateManagement;
