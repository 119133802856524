import { FileOutlined, PictureOutlined, PlaySquareOutlined } from '@ant-design/icons';
import trimText from '@helpers/trim-text';
import type { Message } from '@interfaces/message';
import buildClassName from '@utils/build-class-name';
import type { FC } from 'react';
import { useCallback } from 'react';

import styles from './EmbeddedMessage.module.scss';

const NBSP = '\u00A0';
const TRUNCATED_SUMMARY_LENGTH = 80;

interface OwnProps {
  message?: Message;
  onClick: () => void;
  isEditedMessage: boolean;
  className?: string;
}

const EmbeddedMessage: FC<OwnProps> = ({ message, onClick, isEditedMessage, className }) => {
  const isMediaMessage = message?.type !== 'text';

  const getMediaIcon = useCallback(() => {
    switch (message?.type) {
      case 'photo':
        return <PictureOutlined />;
      case 'audio':
      case 'video':
      case 'voice':
        return <PlaySquareOutlined />;
      case 'document':
        return <FileOutlined />;
      default:
        return null;
    }
  }, [message?.type]);

  function renderTextContent() {
    if (!message?.body) {
      switch (message?.type) {
        case 'photo':
          return 'Photo';
        case 'video':
          return 'Video';
        case 'audio':
          return 'Audio';
        case 'voice':
          return 'Voice';
        case 'document':
          return 'Document';
        default:
          return NBSP;
      }
    }

    // If the message contains both the original and edited message, we only want to keep the edited message
    return trimText(
      message?.body?.replace(/[\s\S]+The\sedited\s(?:\(new\)\s)?(?:message|caption)\sis\s"(.+)".?/, '$1'),
      TRUNCATED_SUMMARY_LENGTH,
    );
  }

  function renderSender() {
    if (!message?.author_name) {
      return NBSP;
    }

    return (
      <>
        <span className="embedded-sender">{isEditedMessage ? 'Original Message' : message.author_name}</span>
      </>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
    <div
      className={buildClassName(
        styles.embeddedMessage,
        className,
        isMediaMessage && styles.withThumb,
        'embedded-reply-message',
      )}
      title={isEditedMessage ? 'Original message' : undefined}
      onClick={onClick}
      role="link"
    >
      {isMediaMessage && <div className={styles.embeddedThumb}>{getMediaIcon()}</div>}
      <div className={styles.messageText}>
        <p className={styles.embeddedTextWrapper}>{renderTextContent()}</p>
        <div className={styles.messageTitle}>{renderSender()}</div>
      </div>
    </div>
  );
};

export default EmbeddedMessage;
