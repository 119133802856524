import useDocumentTitle from '@hooks/use-document-title';
import useLastCallback from '@hooks/use-last-callback';
import SearchInput from '@pages/ChatFeed/ConversationList/SearchInput';
import type { LoaderData } from '@services/types/loader-data';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useLoaderData } from 'react-router-dom';
import type { SortMatchResultType } from 'string-comparison';
import stringComparison from 'string-comparison';

import styles from './ConversationList.module.scss';
import ConversationListItem from './ConversationListItem';
import type { loader } from './loader';

const ConversationList: FC = () => {
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [conversations, setConversations] = useState(useLoaderData() as LoaderData<typeof loader>);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredConversations, setFilteredConversations] = useState(conversations);
  const totalUnreadCount = useMemo(
    () => conversations.reduce((acc, conversation) => acc + conversation.unread, 0),
    [conversations],
  );
  useDocumentTitle(totalUnreadCount > 0 ? `(${totalUnreadCount}) ` : '', true, ' ');

  const onConversationLatestTimeUpdated = useLastCallback((id: number, updatedAt: string) => {
    setConversations((conversations) =>
      conversations
        .map((conversation) => (conversation.id === id ? { ...conversation, time: updatedAt } : conversation))
        .sort((a, b) => {
          if (a.time === null) {
            return 1;
          }

          if (b.time === null) {
            return -1;
          }

          return a.time > b.time ? -1 : a.time < b.time ? 1 : 0;
        }),
    );
  });

  const onConversationUnreadUpdated = useLastCallback((id: number, unreadCount: number) => {
    setConversations((conversations) =>
      conversations.map((conversation) =>
        conversation.id === id ? { ...conversation, unread: unreadCount } : conversation,
      ),
    );
  });

  const onConversationAuthorizationUpdated = useLastCallback((id: number, authorized: boolean) => {
    setConversations((conversations) =>
      conversations
        .map((conversation) => (conversation.id === id ? { ...conversation, is_authorized: authorized } : conversation))
        .filter((conversation) => conversation.is_authorized),
    );
  });

  const filterConversations = useCallback(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const RATING_THRESHOLD = 0;
    const INCLUSION_WEIGHT = 0.1;
    const sortedNames = new Map<string, SortMatchResultType>(
      stringComparison.diceCoefficient
        .sortMatch(
          searchQuery,
          conversations.map((conversation) => conversation.name),
        )
        .map((sorted) => {
          const includesSearchQuery = sorted.member.toLowerCase().includes(lowerCaseQuery);
          if (includesSearchQuery) {
            sorted.rating += INCLUSION_WEIGHT;
          } else {
            sorted.rating -= INCLUSION_WEIGHT;
          }

          sorted.rating = sorted.rating < 0 ? 0 : sorted.rating;

          return [sorted.member, sorted];
        }),
    );
    setFilteredConversations(
      conversations
        .filter((conversation) => {
          const rating = sortedNames.get(conversation.name)?.rating;
          return searchQuery.length === 1 || (rating && rating > RATING_THRESHOLD);
        })
        .sort((conversation1, conversation2) => {
          const rating1 = sortedNames.get(conversation1.name)?.rating;
          const rating2 = sortedNames.get(conversation2.name)?.rating;

          return (rating1 !== undefined && rating2 !== undefined && rating2 - rating1) || 0;
        }),
    );
  }, [conversations, searchQuery]);

  useEffect(() => {
    const query = searchQuery.trim();
    if (query === '') {
      setFilteredConversations(conversations);
      return;
    }

    filterConversations();
  }, [conversations, filterConversations, searchQuery]);

  const handleSearchQuery = useLastCallback((query: string) => {
    setSearchQuery(query);
  });

  const onConversationItemClick = useLastCallback(() => {
    if (searchQuery.length > 0) {
      setSearchQuery('');
    }
  });

  return (
    <div className={styles.conversationList}>
      <SearchInput onChange={handleSearchQuery} value={searchQuery} />
      {filteredConversations
        ? filteredConversations.map((conversation) => (
            <NavLink
              onClick={onConversationItemClick}
              key={conversation.id}
              to={`${conversation.id}`}
              className={!conversation.is_authorized ? styles.locked : ''}
              unstable_viewTransition
            >
              {({ isActive }) => (
                <ConversationListItem
                  id={conversation.id}
                  title={conversation.name}
                  readonly={!conversation.is_authorized}
                  text={conversation.latestMessage}
                  firstUnreadId={conversation.firstUnreadId}
                  active={isActive}
                  unreadCount={conversation.unread}
                  onUpdatedAt={onConversationLatestTimeUpdated}
                  onUnreadUpdated={onConversationUnreadUpdated}
                  onAuthorizationUpdated={onConversationAuthorizationUpdated}
                />
              )}
            </NavLink>
          ))
        : ''}
    </div>
  );
};

export default ConversationList;
