import useEffectOnce from '@hooks/use-effect-once';
import usePrevious from '@hooks/use-previous';
import { useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSyncEffect = <const T extends readonly any[]>(
  effect: (args: T | readonly []) => (() => void) | void,
  dependencies: T,
) => {
  const prevDeps = usePrevious<T>(dependencies);
  const cleanupRef = useRef<() => void>();

  if (!prevDeps || dependencies.some((d, i) => d !== prevDeps[i])) {
    cleanupRef.current?.();
    cleanupRef.current = effect(prevDeps || []) ?? undefined;
  }

  useEffectOnce(() => {
    return () => {
      cleanupRef.current?.();
    };
  });
};

export default useSyncEffect;
