import type { AuthContextType } from '@context/auth.context';
import type { TelegramGroup } from '@interfaces/telegram-group';
import { apiRequest, isResponse } from '@services/fetcher';
import { ConflictError } from '@services/types/conflict-error';
import { ValidationError } from '@services/types/validation-error';
import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const data = await request.json();
    const intent = data.intent;
    delete data.intent;

    if (intent === 'authorize') {
      const response = await apiRequest<TelegramGroup>(`${import.meta.env.VITE_API_URL}/manage/telegram-group`, auth, {
        method: request.method,
        signal: request.signal,
        headers: request.headers,
        body: JSON.stringify(data),
      });

      if (response instanceof Error) {
        if (response instanceof ValidationError || response instanceof ConflictError) {
          return json({
            ok: false,
            statusText: response.message,
          });
        }

        throw response;
      }

      if (isResponse(response)) {
        return {
          status: response.status,
          statusText: response.statusText,
        } as Response;
      }

      return response;
    } else if (intent === 'update') {
      const groupId = data.id;
      const response = await apiRequest<TelegramGroup>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-group/${groupId}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          headers: request.headers,
          body: JSON.stringify(data),
        },
      );

      if (response instanceof Error) {
        if (response instanceof ValidationError) {
          return json({
            ok: false,
            statusText: response.message,
          });
        }

        throw response;
      }

      return response;
    } else {
      const groupId = data.id;
      const response = await apiRequest<Response>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-group/${groupId}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          headers: request.headers,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    }
  }) satisfies ActionFunction;
