import { SearchOutlined } from '@ant-design/icons';
import type { OffsetPaginatedResponse } from '@utils/api/api-response';
import type { GetProp, InputRef, TableProps } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnType as AntDColumnType } from 'antd/es/table/interface';
import type { ParsedQs } from 'qs';
import { parse, stringify } from 'qs';
import type { ForwardedRef, PropsWithoutRef, ReactNode } from 'react';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useNavigation, useSearchParams } from 'react-router-dom';

export type TableFilters = Parameters<GetProp<TableProps, 'onChange'>>[1];

const stringifyFilters = (object: unknown) =>
  stringify(object, {
    indices: false,
    skipNulls: true,
    sort: (a: string, b: string) => a.localeCompare(b),
    encoder: (str, defaultEncoder, _, type) => {
      if (type === 'value' && str === '') {
        return 'null';
      }
      return defaultEncoder(str);
    },
  });

const parseParamsFromQueryString = (query: string) =>
  parse(query, {
    decoder: (str, defaultDecoder, _, type) => {
      if (type === 'value' && str === 'null') {
        return '';
      }
      return defaultDecoder(str);
    },
  });

const parseFiltersFromQueryString = (query: string) => {
  const parsedParams = parseParamsFromQueryString(query);

  if (!('filter' in parsedParams)) {
    return undefined;
  }

  return parsedParams['filter'] as ParsedQs;
};

const filtersObjectToTableFilters = (filters?: ParsedQs) =>
  filters
    ? (Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
          key,
          Array.isArray(value) ? (value as string[]) : [value as string],
        ]),
      ) as TableFilters)
    : undefined;

// Re-declare forwardRef
declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => ReactNode,
  ): (props: PropsWithoutRef<P> & React.RefAttributes<T>) => ReactNode;
}

export interface PaginatedFilteredTableRef {
  resetFilters: () => void;
}

export type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface FilterableColumProps {
  closeButtonEnabled?: boolean;
  closeText?: string;
  filterButtonEnabled?: boolean;
  filterText?: string;
  searchText?: string;
  searchIcon?: ReactNode;
  resetText?: string;
  placeholder?: string;
}

interface ColumnType<RecordType = unknown> extends AntDColumnType<RecordType> {
  key: string;
  filterable?: FilterableColumProps | boolean;
}

export type ColumnsType<RecordType = unknown> = ColumnType<RecordType>[];

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: TableFilters;
}

interface OwnProps<DataType extends Record<string, unknown>>
  extends Omit<TableProps<DataType>, 'pagination' | 'onChange' | 'columns'> {
  data: OffsetPaginatedResponse<DataType>;
  columns: ColumnsType<DataType>;
  onPageChange?: (page: number, pageSize: number, query?: string) => void;
  onFiltersChange?: (filters?: ParsedQs) => void;
}

const PaginatedFilteredTableInner = <DataType extends Record<string, unknown>>(
  props: OwnProps<DataType>,
  ref: ForwardedRef<PaginatedFilteredTableRef>,
): ReactNode => {
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, onPageChange, onFiltersChange, columns, ...tableProps } = props;
  const [tableData, setTableData] = useState<DataType[]>(data.data);
  const [loading, setLoading] = useState(tableProps.loading || false);
  const searchInput = useRef<InputRef>(null);

  const appliedFilters = useMemo(() => parseFiltersFromQueryString(searchParams.toString()), [searchParams]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: Number(searchParams.get('page')) || 1,
      pageSize: Number(searchParams.get('pageSize')) || 10,
    },
    filters: filtersObjectToTableFilters(appliedFilters),
  });

  useEffect(() => {
    setTableParams((params) => ({
      ...params,
      filters: filtersObjectToTableFilters(appliedFilters),
    }));
    onFiltersChange?.(appliedFilters);
  }, [appliedFilters, onFiltersChange]);

  const resetFilters = () => {
    setTableParams((params) => ({
      ...params,
      filters: undefined,
    }));
  };

  useImperativeHandle(ref, () => ({
    resetFilters,
  }));

  useEffect(() => {
    setLoading(navigation.state === 'loading');
  }, [navigation.state]);

  const getColumnSearchProps = (key: string, props?: FilterableColumProps): AntDColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${props?.placeholder || key}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(
              e.target.value && e.target.value.replace(/^\s+/, '') !== '' ? [e.target.value.replace(/^\s+/, '')] : [],
            )
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              confirm();
              return;
            } else if (event.key === 'Escape') {
              close();
              return;
            }
          }}
          style={{ display: 'block', marginBottom: '.5rem' }}
        />
        <Space.Compact block>
          <Button
            type="primary"
            onClick={() => confirm()}
            disabled={selectedKeys.length === 0 || selectedKeys[0] === ''}
            icon={
              props?.searchIcon ? (
                props.searchIcon
              ) : props?.searchIcon === false || props?.searchIcon === null ? undefined : (
                <SearchOutlined />
              )
            }
            size="small"
            block
            style={{ minWidth: 90 }}
          >
            {props?.searchText ? props.searchText : 'Filter'}
          </Button>
          <Button
            size="small"
            disabled={selectedKeys?.[0] === ''}
            onClick={() => {
              setSelectedKeys(['']);
              confirm();
            }}
            block
            style={{ minWidth: 90 }}
          >
            Empty
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
              }
              confirm();
            }}
            disabled={
              tableParams.filters == undefined ||
              !(key in tableParams.filters) ||
              (key in tableParams.filters && tableParams.filters[key] === null)
            }
            size="small"
            block
            style={{ minWidth: 90 }}
          >
            {props?.resetText ? props.resetText : 'Reset'}
          </Button>
          {props?.filterButtonEnabled && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
              disabled={selectedKeys.length === 0}
            >
              {props?.filterText ? props.filterText : 'Search'}
            </Button>
          )}
          {props?.closeButtonEnabled && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {props?.closeText ? props.closeText : 'Close'}
            </Button>
          )}
        </Space.Compact>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{
          color: filtered ? 'var(--ant-color-primary)' : 'var(--ant-table-header-color)',
          fontSize: 'var(--ant-font-size-lg)',
        }}
      />
    ),
    filtered: tableParams.filters !== undefined && key in tableParams.filters && tableParams.filters[key] !== null,
    filteredValue: tableParams.filters?.[key] || null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const tableColumns = columns.map((column) => ({
    ...column,
    ...(column.filterable && column.dataIndex
      ? getColumnSearchProps(column.key, typeof column.filterable !== 'boolean' ? column.filterable : undefined)
      : {}),
  }));

  useEffect(() => {
    setTableData(data.data);
  }, [data.data]);

  useEffect(() => {
    setTableParams((params) => ({
      ...params,
      pagination: {
        ...params.pagination,
        current: data.meta?.current,
        pageSize: data.meta?.pageSize,
        total: data.meta?.total,
      },
    }));
  }, [data.meta]);

  const handleTableChange: TableProps['onChange'] = (pagination, filters) => {
    const previousTableParams = tableParams;
    setTableParams((params) => ({
      ...params,
      pagination,
      filters,
    }));

    if (pagination.pageSize !== previousTableParams.pagination?.pageSize) {
      setTableData([]);
    }

    if (
      pagination.current &&
      pagination.pageSize &&
      (previousTableParams.pagination?.current !== pagination.current ||
        previousTableParams.pagination?.pageSize !== pagination.pageSize)
    ) {
      setSearchParams(
        (params) =>
          new URLSearchParams({
            ...Object.fromEntries(params),
            ...{
              page: `${pagination.current}`,
              pageSize: `${pagination.pageSize}`,
            },
          }),
      );
      onPageChange?.(
        pagination.current,
        pagination.pageSize,
        filters ? stringifyFilters({ filter: filters }) : undefined,
      );
    }

    // TODO: Instead of comparing query strings, we can use deep object comparison
    const currentFilters = stringifyFilters({ filter: filters });
    const previousFilters = stringifyFilters({ filter: tableParams.filters });
    if (currentFilters !== previousFilters) {
      setSearchParams(
        (params) =>
          new URLSearchParams({
            ...(params.has('page')
              ? {
                  page: '1', // reset to first page if filters are changed
                  pageSize: params.get('pageSize')!,
                }
              : {}),
            ...Object.fromEntries(new URLSearchParams(currentFilters)),
          }),
      );
    }
  };

  return (
    <Table<DataType>
      {...tableProps}
      dataSource={tableData}
      columns={tableColumns}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />
  );
};

const PaginatedFilteredTable = forwardRef(PaginatedFilteredTableInner);

export default PaginatedFilteredTable;
