import { CheckOutlined, ClockCircleOutlined, EditOutlined, EnterOutlined, StopOutlined } from '@ant-design/icons';
import type { Message } from '@interfaces/message';
import { formatDateTimeToString, formatTime } from '@utils/date-format';
import type { FC } from 'react';
import { useMemo } from 'react';

const NBSP = '\u00A0';

interface OwnProps {
  message: Message;
  isOwn: boolean;
  className?: string;
}

const MessageMeta: FC<OwnProps> = ({ message, className, isOwn }) => {
  const messageDate = useMemo(() => new Date(message.created_at), [message.created_at]);
  const title = formatDateTimeToString(messageDate);

  return (
    <div className={className} title={title} dir="ltr">
      {message.isEdited && 'edited '}
      {!!message.edited_message_id && (
        <>
          <EditOutlined />
          {NBSP}
        </>
      )}
      {message.hasReply && (
        <>
          <EnterOutlined style={{ transform: 'scaleX(-1) rotate(180deg)' }} />
          {NBSP}
        </>
      )}
      {formatTime(messageDate)}
      {isOwn &&
        (message.id === message.localId ? (
          message.tg_message_id ? (
            <CheckOutlined style={{ marginLeft: '0.2rem' }} />
          ) : (
            <StopOutlined
              style={{ marginLeft: '0.2rem', color: 'red' }}
              title="There was a problem sending the message."
            />
          )
        ) : (
          <ClockCircleOutlined style={{ marginLeft: '0.1rem' }} />
        ))}
    </div>
  );
};

export default MessageMeta;
