import useBackgroundMode from '@hooks/use-background-mode';
import { useIntersectionObserver } from '@hooks/use-intersection-observer';
import useSocket from '@hooks/use-socket';
import { markMessageRead } from '@utils/api/markMessageRead';
import type { RefObject } from 'react';

const INTERSECTION_THROTTLE_FOR_READING = 150;

function useMessageObservers(
  containerRef: RefObject<HTMLDivElement>,
  memoLastReadIdRef: { current?: number | null },
  conversationId: number,
) {
  const socket = useSocket();
  const {
    observe: observeIntersectionForReading,
    freeze: freezeForReading,
    unfreeze: unfreezeForReading,
  } = useIntersectionObserver(
    {
      rootRef: containerRef,
      throttleMs: INTERSECTION_THROTTLE_FOR_READING,
    },
    (entries) => {
      let maxId = 0;

      entries.forEach((entry) => {
        const { isIntersecting, target } = entry;

        if (!isIntersecting) {
          return;
        }

        const { dataset } = target as HTMLDivElement;
        const messageId = Number(dataset.lastMessageId || dataset.messageId);

        if (messageId > maxId) {
          maxId = messageId;
        }
      });

      if ((memoLastReadIdRef.current && maxId > memoLastReadIdRef.current) || memoLastReadIdRef.current === null) {
        markMessageRead(socket, conversationId, maxId);
      }
    },
  );

  useBackgroundMode(freezeForReading, unfreezeForReading);

  return { observeIntersectionForReading };
}

export default useMessageObservers;
