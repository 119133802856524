import type { Message } from '@interfaces/message';

export function appendReplyAndEditedMessages(messageIds: number[], messagesById: Record<number, Message>): Message[] {
  return messageIds
    .map((id) => messagesById[id])
    .filter(Boolean)
    .map((message) => {
      const editedMessage = message.edited_message_id ? messagesById[message.edited_message_id] : undefined;
      const replyMessage = message.reply_to_id ? messagesById[message.reply_to_id] : undefined;

      if (editedMessage) {
        editedMessage.isEdited = true;
      }

      if (replyMessage) {
        replyMessage.hasReply = true;
      }

      return {
        ...message,
        replyMessage,
        editedMessage,
      };
    });
}
