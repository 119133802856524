import type { Message } from '@interfaces/message';
import { getDayStart } from '@utils/date-format';

type SenderGroup = Message[];

export interface MessageDateGroup {
  originalDate: string;
  datetime: number;
  senderGroups: SenderGroup[];
}

export function groupMessages(messages: Message[], lastReadId?: number | null) {
  let currentSenderGroup: SenderGroup = [];
  let currentDateGroup: MessageDateGroup = {
    originalDate: messages[0].created_at,
    datetime: getDayStart(messages[0].created_at).getTime(),
    senderGroups: [currentSenderGroup],
  };

  const dateGroups: MessageDateGroup[] = [currentDateGroup];

  messages.forEach((message, index) => {
    currentSenderGroup.push(message);

    const nextMessage = messages[index + 1];

    if (nextMessage) {
      const nextMessageDayStartsAt = getDayStart(nextMessage.created_at).getTime();
      if (currentDateGroup.datetime !== nextMessageDayStartsAt) {
        currentDateGroup = {
          originalDate: nextMessage.created_at,
          datetime: nextMessageDayStartsAt,
          senderGroups: [],
        };

        dateGroups.push(currentDateGroup);
        currentSenderGroup = [];
        currentDateGroup.senderGroups.push(currentSenderGroup);
      } else if (
        messagesHasDifferentSenders(message, nextMessage) ||
        message.id === lastReadId ||
        message.localId === lastReadId
      ) {
        currentSenderGroup = [];
        currentDateGroup.senderGroups.push(currentSenderGroup);
      }
    }
  });

  return dateGroups;
}

function messagesHasDifferentSenders(firstMessage: Message, secondMessage: Message) {
  return (
    (firstMessage.user_id !== null && secondMessage.user_id !== firstMessage.user_id) ||
    (firstMessage.telegram_user_id !== null && secondMessage.telegram_user_id !== firstMessage.telegram_user_id)
  );
}
