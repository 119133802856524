import withCache from '@utils/with-cache';

function toIsoString(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getDayStart(datetime: number | Date | string) {
  const date = new Date(datetime);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getDayStartAt(datetime: number | Date) {
  return getDayStart(datetime).getTime();
}

export function formatHumanDate(datetime: number | Date, isShort = false, noWeekdays = false, isUpperFirst?: boolean) {
  const date = typeof datetime === 'number' ? new Date(datetime) : datetime;
  const today = getDayStart(new Date());

  if (noWeekdays) {
    if (toIsoString(date) === toIsoString(today)) {
      return (isUpperFirst || !isShort ? upperFirst : lowerFirst)('today');
    }

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (toIsoString(date) === toIsoString(yesterday)) {
      return (isUpperFirst || !isShort ? upperFirst : lowerFirst)('yesterday');
    }

    const weekAgo = new Date(today);
    const weekAhead = new Date(today);
    weekAgo.setDate(today.getDate() - 7);
    weekAhead.setDate(today.getDate() + 7);

    if (date >= weekAgo && date <= weekAhead) {
      const weekDayString = isShort
        ? date.toLocaleDateString('en-US', { weekday: 'short' })
        : date.toLocaleDateString('en-US', { weekday: 'long' });

      return (isUpperFirst || !isShort ? upperFirst : lowerFirst)(weekDayString);
    }
  }

  const noYear = date.getFullYear() === today.getFullYear();
  const formattedDate = formatDateToString(date, 'en-US', noYear, isShort ? 'short' : 'long');

  return (isUpperFirst || !isShort ? upperFirst : lowerFirst)(formattedDate);
}

const formatDayToStringWithCache = withCache(
  (
    dayStartAt: number,
    locale: string,
    noYear?: boolean,
    monthFormat: 'short' | 'long' | 'numeric' = 'short',
    noDay?: boolean,
  ) => {
    return new Date(dayStartAt).toLocaleString(locale, {
      year: noYear ? undefined : 'numeric',
      month: monthFormat,
      day: noDay ? undefined : 'numeric',
    });
  },
);

export function formatDateToString(
  datetime: Date | number,
  locale = 'en-US',
  noYear = false,
  monthFormat: 'short' | 'long' | 'numeric' = 'short',
  noDay = false,
) {
  const date = typeof datetime === 'number' ? new Date(datetime) : datetime;
  const dayStartAt = getDayStartAt(date);

  return formatDayToStringWithCache(dayStartAt, locale, noYear, monthFormat, noDay);
}

export function formatDateTimeToString(
  datetime: Date | number,
  locale = 'en-US',
  noSeconds?: boolean,
  timeFormat?: '24h' | '12h',
) {
  const date = typeof datetime === 'number' ? new Date(datetime) : datetime;
  return date.toLocaleString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: noSeconds ? undefined : 'numeric',
    hourCycle: timeFormat === '12h' ? 'h12' : 'h23',
  });
}

function lowerFirst(str: string) {
  return `${str[0].toLowerCase()}${str.slice(1)}`;
}

function upperFirst(str: string) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function formatTime(datetime: number | Date) {
  const date = typeof datetime === 'number' ? new Date(datetime) : datetime;

  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}
