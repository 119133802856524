import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import type { ColumnsType, PaginatedFilteredTableRef } from '@components/PaginatedFilteredTable';
import PaginatedFilteredTable from '@components/PaginatedFilteredTable';
import { languages } from '@interfaces/language';
import type { TelegramGroup } from '@interfaces/telegram-group';
import BulkMessageForm from '@pages/Management/ConversationManagement/BulkMessageForm';
import type { LoaderData } from '@services/types/loader-data';
import { Badge, Button, Form, message, Space, Tooltip } from 'antd';
import type { ParsedQs } from 'qs';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';

import EditableCell from '../EditableCell';
import type { EditableCellProps } from '../EditableCell/EditableCell';
import styles from './ConversationManagement.module.scss';
import CreateConversationForm from './CreateConversationForm';
import type { loader } from './loader';

interface ConversationForm {
  name: string;
  language_code: string;
  is_authorized: boolean;
}

const isErrorResponse = (response: Response | TelegramGroup): response is Response => {
  return 'ok' in response && !response.ok;
};

const ConversationManagement: FC = () => {
  const loaderData = useLoaderData() as LoaderData<typeof loader>;
  const [data, setData] = useState(loaderData);
  const [groups, setGroups] = useState(data.data);
  const { submit, state, data: response } = useFetcher<Response | TelegramGroup>();
  const [loading, setLoading] = useState(false);
  const [isCreateConversationModalOpen, setCreateConversationModalOpen] = useState(false);
  const [isBulkMessageModalOpen, setBulkMessageModalOpen] = useState(false);
  const [form] = Form.useForm<ConversationForm>();
  const [editingKey, setEditingKey] = useState<number>();
  const tableRef = useRef<PaginatedFilteredTableRef>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [appliedFilters, setAppliedFilters] = useState<ParsedQs>();

  useEffect(() => {
    setLoading(state === 'submitting' || state === 'loading');
  }, [state]);

  useEffect(() => {
    setData(loaderData);
  }, [loaderData]);

  useEffect(() => {
    setGroups(data.data);
  }, [data.data]);

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'name',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    setEditingKey(undefined);
  }, [form, response]);

  const isEditing = (record: TelegramGroup) => record.id === editingKey;

  const onSave = useCallback(
    async (id: number) => {
      try {
        const values = await form.validateFields();
        const initialGroup = groups?.find((group) => group.id === id);
        if (initialGroup === undefined) {
          return;
        }

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => {
              return initialGroup[key as keyof TelegramGroup] !== value;
            })
            .map(([key, value]) => {
              return [key, value === undefined ? '' : value];
            }),
        );

        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update',
              id: `${id}`,
              ...changedValues,
            },
            {
              method: 'patch',
              encType: 'application/json',
            },
          );
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [form, groups, submit],
  );

  const columns: ColumnsType<TelegramGroup> = [
    {
      key: 'id',
      title: 'Telegram group ID',
      dataIndex: 'id',
    },
    {
      key: 'name',
      title: 'Conversation name',
      dataIndex: 'name',
      filterable: true,
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'name',
        inputType: 'text',
        title: 'Name',
        editing: isEditing(record),
        max: 96,
      }),
    },
    {
      key: 'language_code',
      title: 'Language',
      dataIndex: 'language_code',
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'language_code',
        inputType: 'select',
        selectOptions: Object.entries(languages).map(([key, value]) => ({
          value: key,
          label: value,
        })),
        title: 'Language',
        editing: isEditing(record),
      }),
      render: (value: string) => {
        return value in languages ? languages[value] : value;
      },
    },
    {
      key: 'is_authorized',
      title: 'Authorized',
      dataIndex: 'is_authorized',
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'is_authorized',
        inputType: 'switch',
        title: 'Autonomous',
        editing: isEditing(record),
        required: false,
      }),
      render: (_, record) => (
        <Badge status={record.is_authorized ? 'success' : 'error'} text={record.is_authorized ? 'Yes' : 'No'} />
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            {editable ? (
              <>
                <Button type="primary" icon={<SaveOutlined />} title="Save" onClick={() => onSave(record.id)} />
                <Button
                  type="default"
                  icon={<StopOutlined />}
                  title="Cancel"
                  onClick={() => setEditingKey(undefined)}
                />
              </>
            ) : (
              <>
                <Button
                  type="default"
                  icon={<EditOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      name: record.name,
                      language_code: record.language_code,
                      is_authorized: record.is_authorized,
                    });
                    setEditingKey(record.id);
                  }}
                />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.buttons}>
        <Tooltip title="Send bulk message to all conversations. Use the conversation filter function to message a specific subset.">
          <Button
            type="default"
            onClick={() => {
              setBulkMessageModalOpen(true);
            }}
            disabled={state === 'submitting' || state === 'loading'}
          >
            Bulk Message
          </Button>
        </Tooltip>
        <Button
          type="primary"
          onClick={() => {
            setCreateConversationModalOpen(true);
          }}
          disabled={state === 'submitting' || state === 'loading'}
        >
          Create Conversation
        </Button>
      </div>
      <BulkMessageForm
        open={isBulkMessageModalOpen}
        onCancel={() => {
          setBulkMessageModalOpen(false);
        }}
        onSent={() => {
          setBulkMessageModalOpen(false);
          // noinspection JSIgnoredPromiseFromCall
          messageApi.info('Sending message queued.');
        }}
        filters={appliedFilters}
        isFormDisabled={groups.length === 0}
      />
      {isCreateConversationModalOpen ? (
        <CreateConversationForm
          open={isCreateConversationModalOpen}
          onCreate={() => {
            setCreateConversationModalOpen(false);
          }}
          onCancel={() => {
            setCreateConversationModalOpen(false);
          }}
        />
      ) : null}
      <Form form={form} component={false}>
        <PaginatedFilteredTable<TelegramGroup>
          ref={tableRef}
          data={data}
          columns={columns}
          rowKey="id"
          loading={loading}
          rowClassName="editable-row"
          components={{ body: { cell: EditableCell } }}
          onFiltersChange={(filters) => setAppliedFilters(filters)}
        />
      </Form>
      {contextHolder}
    </>
  );
};

export default ConversationManagement;
