import './App.scss';
import 'antd/dist/reset.css';

import AuthenticatedLayout from '@components/AuthenticatedLayout';
import ErrorBoundary from '@components/ErrorBoundary';
import RequireAuth from '@components/RequireAuth';
import { SocketProvider } from '@context/socket.context';
import useAuth from '@hooks/use-auth';
import usePrefersColorScheme from '@hooks/use-prefers-color-scheme';
import { Role } from '@interfaces/role';
import ChatFeed from '@pages/ChatFeed';
import { loader as conversationsListLoader } from '@pages/ChatFeed/ConversationList';
import RightColumn, { loader as conversationLoader } from '@pages/ChatFeed/RightColumn';
import Login, { action as loginAction } from '@pages/Login';
import Management from '@pages/Management';
import ConversationManagement, {
  action as conversationsActions,
  loader as conversationsLoader,
} from '@pages/Management/ConversationManagement';
import MenuItemManagement, {
  action as buttonsActions,
  loader as buttonsLoader,
} from '@pages/Management/MenuManagement';
import MessageTemplateManagement, {
  action as messageTemplatesAction,
  loader as messageTemplatesLoader,
} from '@pages/Management/MessageTemplateManagement';
import OrganizationManagement, {
  action as organizationActions,
  loader as organizationLoader,
} from '@pages/Management/OrganizationManagement';
import TelegramUserManagement, {
  action as telegramUsersAction,
  loader as telegramUsersLoader,
} from '@pages/Management/TelegramUserManagement';
import UserManagement, { action as usersActions, loader as usersLoader } from '@pages/Management/UserManagement';
import OwnMessages, { loader as ownMessageLoader } from '@pages/User/OwnMessages';
import { App as AntApp, ConfigProvider, theme } from 'antd';
import type { FC } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

const App: FC = () => {
  const auth = useAuth();
  const { preferredColorScheme } = usePrefersColorScheme();
  // We need to inject AuthContextType to our actions and loaders as we don't
  // have access to hooks from them, See:
  // https://github.com/remix-run/react-router/discussions/9564
  // https://github.com/remix-run/react-router/pull/9975

  const router = createBrowserRouter(
    [
      {
        path: '/login',
        element: <Login />,
        action: loginAction(auth),
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/',
        element: <AuthenticatedLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <Navigate to="/conversations" replace />,
          },
          {
            path: 'conversations',
            element: (
              <SocketProvider>
                <ChatFeed />
              </SocketProvider>
            ),
            loader: conversationsListLoader(auth),
            children: [
              {
                id: 'conversation',
                path: ':conversationId',
                element: <RightColumn />,
                loader: conversationLoader(auth),
              },
            ],
          },
          {
            path: 'user',
            children: [
              {
                path: 'messages',
                element: <OwnMessages />,
                loader: ownMessageLoader(auth),
              },
            ],
          },
          {
            element: <RequireAuth allowedRoles={[Role.Admin]} />,
            children: [
              {
                path: 'management',
                element: <Management />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="conversations" replace />,
                  },
                  {
                    path: 'users',
                    element: <UserManagement />,
                    loader: usersLoader(auth),
                    action: usersActions(auth),
                  },
                  {
                    path: 'conversations',
                    element: <ConversationManagement />,
                    loader: conversationsLoader(auth),
                    action: conversationsActions(auth),
                  },
                  {
                    path: 'telegram-menu',
                    element: <MenuItemManagement />,
                    loader: buttonsLoader(auth),
                    action: buttonsActions(auth),
                  },
                  {
                    path: 'settings',
                    element: <OrganizationManagement />,
                    loader: organizationLoader(auth),
                    action: organizationActions(auth),
                  },
                  {
                    path: 'telegram-users',
                    element: <TelegramUserManagement />,
                    loader: telegramUsersLoader(auth),
                    action: telegramUsersAction(auth),
                  },
                  {
                    path: 'message-templates',
                    element: <MessageTemplateManagement />,
                    loader: messageTemplatesLoader(auth),
                    action: messageTemplatesAction(auth),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    {
      future: {
        v7_normalizeFormMethod: true,
      },
    },
  );

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        hashed: false,
        algorithm: preferredColorScheme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <AntApp>
        <RouterProvider router={router} />
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
