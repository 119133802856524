import type { TypedSocket } from '@context/socket.context';
import type { ConversationCache } from '@store/types/conversation-cache';
import { debounce } from '@utils/schedulers';

import { getStore } from '@/store';

const emitReadDebounced = debounce((cb) => cb(), 300, false, true);

export const markMessageRead = (socket: TypedSocket, conversationId: number, messageId: number) => {
  const store = getStore<ConversationCache>(`conversation/${conversationId}`);
  emitReadDebounced(async () => {
    socket.emit('read-message', { id: messageId, conversationId });
    store.setState((state) => ({
      ...state,
      lastReadId: messageId,
    }));
  });
};
