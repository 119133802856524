import { MenuItemType } from '@interfaces/menu-item-type';
import type { TelegramMenuItem } from '@interfaces/telegram-menu-item';
import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';

const isErrorResponse = (response: Response | TelegramMenuItem): response is Response => {
  return 'ok' in response && !response.ok;
};

interface MenuItemWithDefaultTranslation {
  id: string;
  frontend_label: string;
  is_autonomous: boolean;
  has_subkeyboard: boolean;
  type: 'button' | 'command';
  command: string | null;
  label: string;
  answer: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (menuItem: Omit<TelegramMenuItem, 'translations'>) => void;
  onCancel: () => void;
}

const MenuItemAddForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const { submit, state, data: response } = useFetcher<Response | TelegramMenuItem>();
  const [form] = Form.useForm<MenuItemWithDefaultTranslation>();
  const [requireCommand, setRequireCommand] = useState(false);

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'frontend_label',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    form.resetFields();
    onCreate(response);
  }, [form, onCreate, response]);

  const onSubmit = useCallback(async () => {
    const values = await form.validateFields();
    submit(
      {
        intent: 'add-menu-item',
        ...Object.fromEntries(Object.entries(values).filter(([, value]) => value !== undefined)),
      },
      {
        method: 'post',
      },
    );
  }, [form, submit]);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      open={open}
      title="Add menu item"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={state === 'submitting'} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={state === 'submitting'}
      >
        <Form.Item
          name="label"
          label="Label (English)"
          rules={[
            {
              required: true,
              message: 'Please input the label!',
            },
            {
              max: 64,
              message: 'Label can not exceed 64 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 64 }} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select the type!',
            },
          ]}
          initialValue="button"
        >
          <Select
            options={Object.entries(MenuItemType).map(([key, value]) => ({
              label: key,
              value,
            }))}
            onChange={(value) => {
              setRequireCommand(value === MenuItemType.Command);
            }}
          />
        </Form.Item>
        {requireCommand && (
          <Form.Item
            name="command"
            label="Command"
            rules={[
              {
                required: requireCommand,
                message: 'Please provide the command!',
              },
              {
                max: 32,
                message: 'Command can not exceed 32 characters.',
              },
              {
                pattern: /^[a-z0-9_]+$/,
                message: 'The command can only contain lowercase English letters, digits and underscores.',
              },
            ]}
          >
            <Input count={{ show: true, max: 32 }} />
          </Form.Item>
        )}
        <Form.Item name="is_autonomous" label="Autonomous" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="has_subkeyboard" label="Subkeyboard" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="answer" label="Telegram message (English)">
          <Input />
        </Form.Item>
        <Form.Item name="frontend_label" label="Web-client message (English)">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MenuItemAddForm;
