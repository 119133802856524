import { FileOutlined } from '@ant-design/icons';
import { getAttachmentType, getFileSizeString } from '@helpers/build-attachment';
import type { Attachment } from '@interfaces/attachment';
import type { FC } from 'react';
import { useMemo } from 'react';

import styles from './AttachmentModalItem.module.scss';

interface OwnProps {
  attachment: Attachment;
  index: number;
  onDelete?: (index: number) => void;
}

const AttachmentModalItem: FC<OwnProps> = ({ attachment, index, onDelete }) => {
  const type = getAttachmentType(attachment);
  const content = useMemo(() => {
    // TODO: content based on type with previews and delete button
    return (
      <>
        <div className="file-icon-container">
          <FileOutlined className={styles.fileIcon} />
        </div>
        <div className={styles.fileInfo}>
          <div className={styles.fileTitle} dir="auto" title={attachment.filename}>
            {attachment.filename}
          </div>
          <div className={styles.subtitle}>{getFileSizeString(attachment.size)}</div>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment, type, index, onDelete]);

  return <div className={styles.root}>{content}</div>;
};

export default AttachmentModalItem;
