import { useStateRef } from '@hooks/use-state-ref';
import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useLastCallback<T extends (...args: any[]) => any>(callback?: T) {
  const ref = useStateRef(callback);

  // No need for ref dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args: Parameters<T>) => ref.current?.(...args), []) as T;
}

export default useLastCallback;
