import type { ValidationErrorResponse } from '@services/types/validation-error-response';
import { isValidationErrorResponse } from '@services/types/validation-error-response';
import type { FormInstance } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

const isErrorResponse = <T extends object>(response: Response | T): response is Response => {
  return 'ok' in response && !response.ok;
};

type Destructor = () => void;

function useFormResponse<T extends object>(
  form: FormInstance | FormInstance[],
  response: T | Response | ValidationErrorResponse | undefined,
  resetFields = true,
  effect?: (response: T) => void | Destructor,
): [string | undefined, Dispatch<SetStateAction<string | undefined>>] {
  const [formError, setFormError] = useState<string>();

  useEffect(() => {
    if (response === undefined) {
      return undefined;
    }

    if (isValidationErrorResponse(response)) {
      const fieldErrors: { name: string; errors: string[] }[][] = [[]];
      const formErrors: string[] = [];
      for (const key in response.details) {
        if (Array.isArray(form)) {
          let hasField = false;
          form.forEach((formInstance, index) => {
            if (!fieldErrors[index]) {
              fieldErrors[index] = [];
            }
            if (formInstance.getFieldInstance(key) !== undefined && response.details) {
              hasField = true;
              fieldErrors[index].push({
                name: key,
                errors: response.details[key],
              });
            }
          });

          if (!hasField) {
            formErrors.push(response.details[key].join(', '));
          }
        } else {
          if (form.getFieldInstance(key) !== undefined) {
            fieldErrors[0].push({
              name: key,
              errors: response.details[key],
            });
          } else {
            formErrors.push(response.details[key].join(', '));
          }
        }
      }

      if (Array.isArray(form)) {
        form.forEach((formInstance, index) => {
          formInstance.setFields(fieldErrors[index]);
        });
      } else {
        form.setFields(fieldErrors[0]);
      }
      setFormError(formErrors.join(', '));
      return;
    }

    if (!isValidationErrorResponse(response) && isErrorResponse(response)) {
      setFormError(response.statusText);
      return;
    }

    setFormError(undefined);

    if (resetFields) {
      if (Array.isArray(form)) {
        form.forEach((formInstance) => formInstance.resetFields());
      } else {
        form.resetFields();
      }
    }

    if (effect) {
      return effect(response as T);
    }
  }, [form, response, resetFields, effect]);

  return [formError, setFormError];
}

export default useFormResponse;
