import calculatePasswordStrength, { passwordValidationOptions } from '@helpers/calculate-password-strength';
import type { ProgressProps } from 'antd';
import { Progress } from 'antd';
import type { FC } from 'react';

const STRENGTH_COLORS: string[] = ['#ff4033', '#ffd908', '#cbe11d', '#6ecc3a'] as const;

interface OwnProps extends Omit<ProgressProps, 'percent' | 'steps' | 'strokeColor' | 'format'> {
  password: string;
}

const PasswordStrengthMeter: FC<OwnProps> = ({ password, ...progressProps }) => {
  const strength = calculatePasswordStrength(password);
  const strengthInPercent = password.length === 0 ? 0 : (strength.id + 1) * (100 / passwordValidationOptions.length);

  return (
    <Progress
      size={[50, 5]}
      {...progressProps}
      percent={strengthInPercent}
      steps={passwordValidationOptions.length}
      strokeColor={STRENGTH_COLORS}
      format={() => (
        <span style={{ color: STRENGTH_COLORS.reverse()[STRENGTH_COLORS.length - strength.id] }}>{strength.value}</span>
      )}
    />
  );
};

export default PasswordStrengthMeter;
