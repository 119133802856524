import type { FormRule } from 'antd';
import type { Options } from 'check-password-strength';
import { passwordStrength } from 'check-password-strength';

export const passwordValidationOptions: Options<string> = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 10,
  },
];
export const maxLevelOfPasswordValidation = passwordValidationOptions.length;

export default function calculatePasswordStrength(password: string) {
  return passwordStrength(password, passwordValidationOptions);
}

export const passwordStrengthValidator =
  (validationLevel = 4): FormRule =>
  () => ({
    validator: (_, value: string | null) => {
      const strength = calculatePasswordStrength(value ?? '');
      const requiredLevel = Math.min(validationLevel, maxLevelOfPasswordValidation) - 1;
      return strength.id === requiredLevel
        ? Promise.resolve()
        : Promise.reject(
            `Your password must be at least ${passwordValidationOptions[requiredLevel].minLength} characters long and include all of the following criterias: lowercase letter, uppercase letter, number, and special character.`,
          );
    },
  });
