import useFormResponse from '@hooks/use-form-response';
import type { MessageTemplate } from '@interfaces/message-template';
import { Alert, Button, Form, Input, Modal } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useFetcher } from 'react-router-dom';

interface TemplateWithDefaultTranslation extends Omit<MessageTemplate, 'translations'> {
  template: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (template: Omit<MessageTemplate, 'translations'>) => void;
  onCancel: () => void;
}

const TemplateAddForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const { submit, state, data: response } = useFetcher<Response | MessageTemplate>();
  const [form] = Form.useForm<TemplateWithDefaultTranslation>();
  const [formError] = useFormResponse<MessageTemplate>(form, response, true, (pureResponse) => {
    onCreate(pureResponse);
  });

  const onSubmit = useCallback(async () => {
    const values = await form.validateFields();
    submit(
      {
        intent: 'add-template',
        ...Object.fromEntries(Object.entries(values).filter(([, value]) => value !== undefined)),
      },
      {
        method: 'post',
        encType: 'application/json',
      },
    );
  }, [form, submit]);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      open={open}
      title="Add template"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={state === 'submitting'} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit} disabled={state === 'submitting'}>
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="slug"
          label="Shortcut"
          rules={[
            {
              required: true,
              message: 'Please input the shortcut!',
            },
            {
              max: 128,
              message: 'The shortcut can not exceed 128 characters.',
            },
            {
              pattern: /^[\w-]+$/,
              message: 'The shortcut can only contain Latin letters, numbers, dashes and underscores.',
            },
          ]}
        >
          <Input count={{ show: true, max: 128 }} />
        </Form.Item>
        <Form.Item
          name="template"
          label="Text (English)"
          rules={[
            {
              required: true,
              message: 'Please input the text for this template!',
            },
            {
              pattern: /\S/,
              message: 'At least one character other than space is required!',
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TemplateAddForm;
