import PasswordStrengthMeter from '@components/PasswordStrengthMeter';
import { passwordStrengthValidator } from '@helpers/calculate-password-strength';
import type { FormItemProps, FormRule } from 'antd';
import { Form, Input } from 'antd';
import type { PasswordProps } from 'antd/es/input';
import { useState } from 'react';

export interface CustomPasswordInputProps {
  showStrengthMeter?: boolean;
  validatePasswordLevel?: 1 | 2 | 3 | 4;
}

interface OwnProps<Values = unknown> extends FormItemProps<Values> {
  inputProps?: PasswordProps & CustomPasswordInputProps;
}

const PasswordInputWithStrengthMeter = <Values = unknown,>(props: OwnProps<Values>) => {
  const { inputProps, ...formItemProps } = props;
  const { showStrengthMeter, validatePasswordLevel, ...restInputProps } = inputProps ?? {};
  const form = Form.useFormInstance<Values>();
  const password = Form.useWatch(formItemProps.name, form);
  const [isTouched, setTouched] = useState(false);

  const formItemRules: FormRule[] = [
    ...(formItemProps.rules ?? []),
    ...(validatePasswordLevel ? [passwordStrengthValidator(validatePasswordLevel)] : []),
  ];

  return (
    <Form.Item
      {...formItemProps}
      rules={formItemRules}
      extra={showStrengthMeter && isTouched && <PasswordStrengthMeter password={password || ''} />}
    >
      <Input.Password {...restInputProps} onFocus={() => setTouched(true)} />
    </Form.Item>
  );
};

export default PasswordInputWithStrengthMeter;
