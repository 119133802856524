import type { ColumnsType } from '@components/PaginatedFilteredTable';
import PaginatedFilteredTable from '@components/PaginatedFilteredTable';
import trimText from '@helpers/trim-text';
import useAuth from '@hooks/use-auth';
import { isResponse } from '@services/fetcher';
import type { LoaderData } from '@services/types/loader-data';
import type { OffsetPaginatedResponse } from '@utils/api/api-response';
import type { UserMessage } from '@utils/api/fetchUserMessages';
import fetchPaginatedUserMessages from '@utils/api/fetchUserMessages';
import { formatDateTimeToString } from '@utils/date-format';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';

import type { loader } from './loader';

const OwnMessages: FC = () => {
  const auth = useAuth();
  const messages = useLoaderData() as LoaderData<typeof loader>;
  const [data, setData] = useState(messages);
  const { state } = useFetcher<Response | OffsetPaginatedResponse<UserMessage>>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(state === 'loading');
  }, [state]);

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setLoading(true);
      fetchPaginatedUserMessages(auth, page, pageSize).then((response) => {
        if (isResponse(response)) {
          return;
        }

        setData(response);
        setLoading(false);
      });
    },
    [auth],
  );

  const columns: ColumnsType<UserMessage> = [
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'created_at',
      render: (date: string) => formatDateTimeToString(new Date(date)),
    },
    {
      key: 'conversation',
      title: 'Company',
      dataIndex: 'name',
    },
    {
      key: 'message',
      title: 'Message/Caption',
      dataIndex: 'body',
      render: (message: string | null) => (message !== null ? trimText(message, 150) : null),
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
    },
  ];

  return (
    <PaginatedFilteredTable<UserMessage>
      data={data}
      columns={columns}
      rowKey="id"
      loading={loading}
      onPageChange={onPageChange}
      style={{ padding: '24px 24px 0' }}
    />
  );
};

export default OwnMessages;
