import { LoadingOutlined } from '@ant-design/icons';
import useMedia from '@hooks/use-media';
import type { ApiAudio, ApiVoice } from '@interfaces/messages';
import { Spin } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

interface OwnProps {
  id: number;
  audio: ApiVoice | ApiAudio;
}

const Audio: FC<OwnProps> = ({ id }) => {
  const [shouldSpinnerRender, setShouldSpinnerRender] = useState(true);
  const url = useMedia(id);

  useEffect(() => {
    setShouldSpinnerRender(url === undefined);
  }, [url]);

  return (
    <Spin indicator={<LoadingOutlined spin />} spinning={shouldSpinnerRender}>
      <div className="media-inner" style={{ minHeight: '50px', minWidth: '50px' }}>
        {url && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls controlsList="nodownload">
            <source src={url} type="audio/ogg" />
            <a href={url}>Download audio</a>
          </audio>
        )}
      </div>
    </Spin>
  );
};

export default Audio;
