import { DeleteOutlined, EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Role } from '@interfaces/role';
import type { User } from '@interfaces/user';
import type { LoaderData } from '@services/types/loader-data';
import { Button, Form, Popconfirm, Space, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';

import EditableCell from '../EditableCell';
import type { EditableCellProps } from '../EditableCell/EditableCell';
import type { loader } from './loader';
import UserCreateForm from './UserCreateForm';
import styles from './UserManagement.module.scss';

const isErrorResponse = (response: Response | User): response is Response => {
  return 'ok' in response && !response.ok;
};

interface UserForm {
  username: string;
  full_name: string;
  password: string;
  role: Role;
  tg_username: string | null;
}

const UserManagement: FC = () => {
  const users = useLoaderData() as LoaderData<typeof loader>;
  const { submit, state, data: response } = useFetcher<Response | User>();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [form] = useForm<UserForm>();
  const [editingKey, setEditingKey] = useState<number>();

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'username',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    setEditingKey(undefined);
  }, [form, response]);

  const isEditing = (record: User) => record.id === editingKey;
  const onSave = useCallback(
    async (id: number) => {
      try {
        const values = await form.validateFields({
          dirty: true,
        });
        const initialUser = users?.find((user) => user.id === id);
        if (initialUser === undefined) {
          return;
        }

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => initialUser[key as keyof User] !== value)
            .map(([key, value]) => [key, value === '' || value === undefined ? null : value]),
        );
        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update',
              id: `${id}`,
              ...changedValues,
            },
            {
              method: 'patch',
              encType: 'application/json',
            },
          );
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [form, submit, users],
  );

  return (
    <>
      <Button
        className={styles.addBtn}
        type="primary"
        onClick={() => {
          setAddModalOpen(true);
        }}
        disabled={state === 'submitting' || state === 'loading'}
      >
        Add User
      </Button>
      {/* https://github.com/remix-run/remix/discussions/2749 */}
      {/* https://github.com/remix-run/remix/pull/3551 */}
      {/* We can probably try to use fetcher.Form directly instead of submit in the modal */}
      {isAddModalOpen ? (
        <UserCreateForm
          open={isAddModalOpen}
          onCreate={() => {
            setAddModalOpen(false);
          }}
          onCancel={() => {
            setAddModalOpen(false);
          }}
        />
      ) : null}
      <Form form={form} component={false}>
        <Table<User>
          dataSource={users}
          rowKey="id"
          loading={state === 'submitting' || state === 'loading'}
          rowClassName="editable-row"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        >
          <Table.Column<User> key="id" title="ID" dataIndex="id" />
          <Table.Column<User>
            key="username"
            title="Username"
            dataIndex="username"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'username',
              inputType: 'text',
              title: 'Username',
              editing: isEditing(record),
              max: 32,
            })}
          />
          <Table.Column<User>
            key="username"
            title="Full Name"
            dataIndex="full_name"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'full_name',
              inputType: 'text',
              title: 'Full Name',
              editing: isEditing(record),
              max: 64,
            })}
          />
          <Table.Column<User>
            key="password"
            title="Password"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'password',
              inputType: 'password',
              title: 'Password',
              placeholder: 'New password (optional)',
              editing: isEditing(record),
              customPasswordInputProps: {
                showStrengthMeter: true,
                validatePasswordLevel: 4,
              },
            })}
          />
          <Table.Column<User>
            key="role"
            title="Role"
            dataIndex="role"
            render={(role: Role) => Object.keys(Role)[Object.values(Role).indexOf(role)]}
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'role',
              inputType: 'select',
              selectOptions: Object.entries(Role).map(([key, value]) => ({
                label: key,
                value,
              })),
              title: 'Role',
              editing: isEditing(record),
            })}
          />

          <Table.Column<User>
            key="tg_username"
            title="TG Username"
            dataIndex="tg_username"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'tg_username',
              inputType: 'text',
              title: 'TG Username',
              editing: isEditing(record),
              disabled: form.getFieldValue('role') !== 'admin',
              required: false,
              customRules: [
                {
                  type: 'string',
                  pattern: /^[a-z0-9_]{5,32}$/,
                  message: 'Username must be at least five characters long and can contain a-z, 0–9, and underscores',
                },
              ],
            })}
          />
          <Table.Column<User>
            key="action"
            title="Actions"
            render={(_, record) => {
              const editable = isEditing(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button type="primary" icon={<SaveOutlined />} title="Save" onClick={() => onSave(record.id)} />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={() => setEditingKey(undefined)}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          form.setFieldsValue({
                            username: record.username,
                            full_name: record.full_name,
                            password: undefined,
                            role: record.role,
                            tg_username: record.tg_username,
                          });
                          setEditingKey(record.id);
                        }}
                      />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => {
                          submit(
                            {
                              intent: 'delete',
                              id: `${record.id}`,
                            },
                            {
                              method: 'delete',
                              encType: 'application/json',
                            },
                          );
                        }}
                      >
                        <Button danger icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </>
  );
};

export default UserManagement;
