import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlusOutlined,
  SaveOutlined,
  StopOutlined,
} from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { languages } from '@interfaces/language';
import { MenuItemType } from '@interfaces/menu-item-type';
import type { TelegramMenuItem } from '@interfaces/telegram-menu-item';
import type { TelegramMenuItemTranslation } from '@interfaces/telegram-menu-item-translation';
import type { LoaderData } from '@services/types/loader-data';
import { Badge, Button, Form, Popconfirm, Space, Table } from 'antd';
import type { ExpandedRowRender } from 'rc-table/lib/interface';
import type { CSSProperties, FC, HTMLAttributes, Key } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';

import EditableCell from '../EditableCell';
import type { EditableCellProps } from '../EditableCell/EditableCell';
import type { loader } from './loader';
import MenuItemAddForm from './MenuItemAddForm';
import MenuItemAddTranslationForm from './MenuItemAddTranslationForm';
import styles from './MenuItemManagement.module.scss';

interface RawContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RawContext = createContext<RawContextProps>({});

const DragHandle: FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RawContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: FC<RowProps> = (props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RawContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RawContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RawContext.Provider>
  );
};

interface MenuItemForm {
  frontend_label: string;
  is_autonomous: boolean;
  has_subkeyboard: boolean;
  type: 'button' | 'command';
  command: string | null;
}

interface MenuItemTranslationForm {
  label: string;
  answer: string;
  language_code: string;
}

// noinspection JSIgnoredPromiseFromCall
const MenuItemManagement: React.FC = () => {
  const fetchedMenuItems = useLoaderData() as LoaderData<typeof loader>;
  const [menuItems, setMenuItems] = useState<TelegramMenuItem[]>();
  const { submit, state, data: response } = useFetcher<Response | TelegramMenuItem>();
  const [isMenuItemAddModalOpen, setMenuItemAddModalOpen] = useState(false);
  const [isMenuItemTranslationAddModalOpen, setMenuItemTranslationAddModalOpen] = useState(false);
  const [addMenuItemTranslationId, setAddMenuItemTranslationId] = useState(0);
  const [addMenuItemTranslationLanguages, setAddMenuItemTranslationLanguages] = useState(languages);
  const [form] = Form.useForm<MenuItemForm>();
  const [translationForm] = Form.useForm<MenuItemTranslationForm>();
  const [menuItemEditingKey, setMenuItemEditingKey] = useState<number>();
  const [translationEditingKey, setTranslationEditingKey] = useState<{ item_id: number; language_code: string }>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);
  const cellItemType = Form.useWatch('type', form);

  useEffect(() => {
    setMenuItems(fetchedMenuItems);
  }, [fetchedMenuItems]);

  const isEditingItem = (record: TelegramMenuItem) => record.id === menuItemEditingKey;
  const isEditingTranslation = (record: TelegramMenuItemTranslation) =>
    record.item_id === translationEditingKey?.item_id && record.language_code === translationEditingKey?.language_code;

  const onSave = useCallback(
    async (id: number) => {
      try {
        const values = await form.validateFields();
        const initialItem = menuItems?.find((item) => item.id === id);
        if (initialItem === undefined) {
          return;
        }
        values.command = values.type === MenuItemType.Button ? '' : values.command;

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => {
              return initialItem[key as keyof TelegramMenuItem] !== value;
            })
            .map(([key, value]) => {
              return [key, value === undefined ? '' : value];
            }),
        );

        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update-menu-item',
              id: `${id}`,
              ...changedValues,
            },
            {
              method: 'patch',
            },
          );
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [menuItems, form, submit],
  );

  const onSaveTranslation = useCallback(
    async (id: number, language_code: string) => {
      try {
        const values = await translationForm.validateFields();
        const initialTranslation = menuItems
          ?.find((item) => item.id === id)
          ?.translations?.find((translation) => translation.language_code === language_code);
        if (initialTranslation === undefined) {
          return;
        }

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => {
              return initialTranslation[key as keyof TelegramMenuItemTranslation] !== value;
            })
            .map(([key, value]) => {
              return [key, value === undefined ? '' : value];
            }),
        );

        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update-translation',
              item_id: `${id}`,
              language_code_previous: language_code,
              ...changedValues,
            },
            {
              method: 'patch',
            },
          );
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [menuItems, submit, translationForm],
  );

  const onTriggerCreateMenuItem = useCallback((menuItem: Omit<TelegramMenuItem, 'translations'>) => {
    setExpandedRowKeys([menuItem.id]);
    setMenuItemAddModalOpen(false);
  }, []);

  const onTriggerCreateMenuItemTranslation = useCallback((translation: TelegramMenuItemTranslation) => {
    setExpandedRowKeys([translation.item_id]);
    setMenuItemTranslationAddModalOpen(false);
  }, []);

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    setMenuItemEditingKey(undefined);

    setTranslationEditingKey(undefined);
  }, [response]);

  const expandedRowRender: ExpandedRowRender<TelegramMenuItem> = (record) => {
    const translations = record.translations;
    const existingLanguages = translations.map((translation) => translation.language_code);

    return (
      <Form form={translationForm} component={false}>
        <Table<TelegramMenuItemTranslation>
          dataSource={translations}
          rowKey={(record) => `${record.item_id}_${record.language_code}`}
          pagination={false}
          rowClassName="editable-row"
          loading={state === 'submitting' || state === 'loading'}
          components={{
            body: { cell: EditableCell },
          }}
        >
          <Table.Column<TelegramMenuItemTranslation>
            key="label"
            dataIndex="label"
            title="Label"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'label',
              inputType: 'text',
              title: 'Label',
              editing: isEditingTranslation(record),
              max: 64,
            })}
          />
          <Table.Column<TelegramMenuItemTranslation>
            key="answer"
            dataIndex="answer"
            title="Telegram message"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'answer',
              inputType: 'text',
              title: 'Telegram message',
              editing: isEditingTranslation(record),
              required: false,
            })}
          />
          <Table.Column<TelegramMenuItemTranslation>
            key="language_code"
            dataIndex="language_code"
            title="Language"
            onCell={(record): Omit<EditableCellProps, 'children'> => ({
              dataIndex: 'language_code',
              inputType: 'select',
              selectOptions: Object.entries(languages)
                .filter(([key]) => key === record.language_code || !existingLanguages.includes(key))
                .map(([key, value]) => ({
                  value: key,
                  label: value,
                })),
              title: 'Language',
              editing: record.language_code !== 'en' && isEditingTranslation(record),
            })}
            render={(value: string) => {
              return value in languages ? languages[value] : value;
            }}
          />
          <Table.Column<TelegramMenuItemTranslation>
            key="actions"
            title="Actions"
            render={(_, record) => {
              const editable = isEditingTranslation(record);
              return (
                <Space size="middle">
                  {editable ? (
                    <>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        title="Save"
                        onClick={() => onSaveTranslation(record.item_id, record.language_code)}
                      />
                      <Button
                        type="default"
                        icon={<StopOutlined />}
                        title="Cancel"
                        onClick={() => setTranslationEditingKey(undefined)}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          translationForm.setFieldsValue({
                            label: record.label,
                            answer: record.answer ?? undefined,
                            language_code: record.language_code,
                          });
                          setTranslationEditingKey({
                            item_id: record.item_id,
                            language_code: record.language_code,
                          });
                        }}
                      />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => {
                          submit(
                            {
                              intent: 'delete-translation',
                              item_id: `${record.item_id}`,
                              language_code: `${record.language_code}`,
                            },
                            {
                              method: 'delete',
                            },
                          );
                        }}
                        disabled={record.language_code === 'en'}
                      >
                        <Button danger icon={<DeleteOutlined />} disabled={record.language_code === 'en'} />
                      </Popconfirm>{' '}
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    );
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over !== null && active.id !== over.id) {
      setMenuItems((prevState) => {
        if (!prevState) {
          return prevState;
        }
        const activeIndex = prevState?.findIndex((record) => record.id === active.id);
        const overIndex = prevState?.findIndex((record) => record.id === over.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      submit(
        {
          intent: 'swap-sort-order',
          src_id: active.id,
          dst_id: over?.id,
        },
        {
          method: 'POST',
        },
      );
    }
  };

  return (
    <>
      <div className={styles.menuItems}>
        <Button
          onClick={() => {
            submit(
              {
                intent: 'notify-telegram',
              },
              {
                method: 'post',
              },
            );
          }}
          disabled={state === 'submitting' || state === 'loading'}
        >
          Push to Telegram
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setMenuItemAddModalOpen(true);
          }}
          disabled={state === 'submitting' || state === 'loading'}
        >
          Add Menu Item
        </Button>
      </div>
      {isMenuItemAddModalOpen ? (
        <MenuItemAddForm
          open={isMenuItemAddModalOpen}
          onCreate={onTriggerCreateMenuItem}
          onCancel={() => {
            setMenuItemAddModalOpen(false);
          }}
        />
      ) : null}
      {isMenuItemTranslationAddModalOpen ? (
        <MenuItemAddTranslationForm
          open={isMenuItemTranslationAddModalOpen}
          onCreate={onTriggerCreateMenuItemTranslation}
          onCancel={() => {
            setMenuItemTranslationAddModalOpen(false);
          }}
          item_id={addMenuItemTranslationId}
          languages={addMenuItemTranslationLanguages}
        />
      ) : null}
      <Form form={form} component={false}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={(menuItems || []).map((item) => item.id)} strategy={verticalListSortingStrategy}>
            <Table<TelegramMenuItem>
              dataSource={menuItems}
              rowKey="id"
              loading={state === 'submitting' || state === 'loading'}
              rowClassName="editable-row"
              components={{
                body: { row: Row, cell: EditableCell },
              }}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: [],
                expandedRowKeys,
                onExpandedRowsChange: (expandedKeys) => {
                  setExpandedRowKeys(expandedKeys);
                },
                expandRowByClick: true,
              }}
              pagination={false}
            >
              <Table.Column<TelegramMenuItem>
                key="sort_order"
                title="Order"
                align="center"
                width="80"
                render={() => <DragHandle />}
              />
              <Table.Column<TelegramMenuItem> key="id" title="ID" dataIndex="id" />
              <Table.Column<TelegramMenuItem>
                key="type"
                title="Type"
                dataIndex="type"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'type',
                  inputType: 'select',
                  title: 'Type',
                  editing: isEditingItem(record),
                  required: true,
                  selectOptions: Object.entries(MenuItemType).map(([key, value]) => ({
                    label: key,
                    value,
                  })),
                })}
                render={(value: MenuItemType) => (value === MenuItemType.Button ? 'Button' : 'Command')}
              />
              <Table.Column<TelegramMenuItem>
                key="command"
                title="Command"
                dataIndex="command"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'command',
                  inputType: 'text',
                  title: 'Command',
                  editing: isEditingItem(record),
                  required: cellItemType === 'command',
                  disabled: cellItemType !== 'command',
                  max: 32,
                  customRules: [
                    {
                      pattern: /^[a-z0-9_]+$/,
                      message: 'The command can only contain lowercase English letters, digits and underscores.',
                    },
                  ],
                })}
              />
              <Table.Column<TelegramMenuItem>
                key="frontend_label"
                title="Web-client message"
                dataIndex="frontend_label"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'frontend_label',
                  inputType: 'text',
                  title: 'Web-client message',
                  editing: isEditingItem(record),
                  required: false,
                })}
              />
              <Table.Column<TelegramMenuItem>
                key="is_autonomous"
                title="Autonomous"
                dataIndex="is_autonomous"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'is_autonomous',
                  inputType: 'switch',
                  title: 'Autonomous',
                  editing: isEditingItem(record),
                  required: false,
                })}
                render={(_, record) => (
                  <Badge
                    status={record.is_autonomous ? 'success' : 'error'}
                    text={record.is_autonomous ? 'Yes' : 'No'}
                  />
                )}
              />
              <Table.Column<TelegramMenuItem>
                key="has_subkeyboard"
                title="Subkeyboard"
                dataIndex="has_subkeyboard"
                onCell={(record): Omit<EditableCellProps, 'children'> => ({
                  dataIndex: 'has_subkeyboard',
                  inputType: 'switch',
                  title: 'Subkeyboard',
                  editing: isEditingItem(record),
                  required: false,
                })}
                render={(_, record) => (
                  <Badge
                    status={record.has_subkeyboard ? 'success' : 'error'}
                    text={record.has_subkeyboard ? 'Yes' : 'No'}
                  />
                )}
              />
              <Table.Column<TelegramMenuItem>
                key="actions"
                title="Actions"
                render={(_, record) => {
                  const editable = isEditingItem(record);
                  return (
                    <Space size="middle">
                      {editable ? (
                        <>
                          <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            title="Save"
                            onClick={(event) => {
                              event.stopPropagation();
                              // noinspection JSIgnoredPromiseFromCall
                              onSave(record.id);
                            }}
                          />
                          <Button
                            type="default"
                            icon={<StopOutlined />}
                            title="Cancel"
                            onClick={(event) => {
                              event.stopPropagation();
                              setMenuItemEditingKey(undefined);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            title="Add Translation"
                            onClick={(event) => {
                              event.stopPropagation();
                              setAddMenuItemTranslationId(record.id);
                              setAddMenuItemTranslationLanguages(
                                Object.fromEntries(
                                  Object.entries(languages).filter(
                                    ([key]) =>
                                      !record.translations
                                        .map((translation) => translation.language_code)
                                        .includes(key),
                                  ),
                                ),
                              );
                              setMenuItemTranslationAddModalOpen(true);
                            }}
                          />
                          <Button
                            type="default"
                            icon={<EditOutlined />}
                            title="Edit"
                            onClick={(event) => {
                              event.stopPropagation();
                              form.setFieldsValue({
                                frontend_label: record.frontend_label ?? undefined,
                                is_autonomous: record.is_autonomous,
                                has_subkeyboard: record.has_subkeyboard,
                                type: record.type,
                                command: record.command ?? undefined,
                              });
                              setMenuItemEditingKey(record.id);
                            }}
                          />
                          <Popconfirm
                            title="Sure to delete menu item with all of it's translations?"
                            onConfirm={(event) => {
                              event?.stopPropagation();
                              submit(
                                {
                                  intent: 'delete-menu-item',
                                  id: `${record.id}`,
                                },
                                {
                                  method: 'delete',
                                },
                              );
                            }}
                            onCancel={(event) => event?.stopPropagation()}
                          >
                            <Button
                              danger
                              title="Delete"
                              icon={<DeleteOutlined />}
                              onClick={(event) => event.stopPropagation()}
                            />
                          </Popconfirm>
                        </>
                      )}
                    </Space>
                  );
                }}
              />
            </Table>
          </SortableContext>
        </DndContext>
      </Form>
    </>
  );
};

export default MenuItemManagement;
