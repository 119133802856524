import type { AuthContextType } from '@context/auth.context';
import fetchTelegramUsers from '@utils/api/fetchTelegramUsers';
import type { LoaderFunction } from 'react-router-dom';

export const loader = (auth: AuthContextType) =>
  (async ({ request }) => {
    const url = new URL(request.url);
    const page = Number(url.searchParams.get('page')) || 1;
    const pageSize = Number(url.searchParams.get('pageSize')) || 10;
    url.searchParams.delete('page');
    url.searchParams.delete('pageSize');

    return await fetchTelegramUsers(auth, url.searchParams.toString() || null, page, pageSize, {
      signal: request.signal,
    });
  }) satisfies LoaderFunction;
