import useLastCallback from '@hooks/use-last-callback';
import buildClassName from '@utils/build-class-name';
import focusEditableElement from '@utils/focus-editable-element';
import type { KeyboardEvent, SyntheticEvent } from 'react';
import { forwardRef, memo, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react';

import { EDITABLE_INPUT_ID } from '../../index';
import styles from './MessageInput.module.scss';

const SAFARI_BR = '<br>';

interface OwnProps {
  id: string;
  editableInputId?: string;
  conversationId: number;
  getHtml: () => string;
  placeholder: string;
  canAutoFocus: boolean;
  onUpdate: (html: string) => void;
  onSend: () => void;
  withMessageTemplatesButton?: boolean;
  isActive: boolean;
}

// eslint-disable-next-line
const NonMemoMessageInput = forwardRef<HTMLDivElement, OwnProps>(
  (
    {
      id,
      editableInputId,
      conversationId,
      getHtml,
      placeholder,
      canAutoFocus,
      onUpdate,
      onSend,
      withMessageTemplatesButton = false,
      isActive,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    const htmlRef = useRef(getHtml());
    useLayoutEffect(() => {
      const html = isActive ? getHtml() : '';

      if (html !== inputRef.current!.innerHTML) {
        inputRef.current!.innerHTML = html;
      }

      if (html !== htmlRef.current) {
        htmlRef.current = html;
      }
    }, [getHtml, isActive]);

    const focusInput = useLastCallback(() => {
      if (!inputRef.current) {
        return;
      }

      focusEditableElement(inputRef.current);
    });

    function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSend();
      }
    }

    function handleChange(e: SyntheticEvent<HTMLDivElement>) {
      const { innerHTML } = e.currentTarget;

      onUpdate(innerHTML === SAFARI_BR ? '' : innerHTML);

      // Reset focus on the input to remove any active styling when input is cleared
      // if (/* !IS_TOUCH_ENV && */ !textContent || !textContent.length) {
      //   const selection = window.getSelection()!;
      //   if (selection) {
      //     inputRef.current!.blur();
      //     selection.removeAllRanges();
      //     focusEditableElement(inputRef.current!, true);
      //   }
      // }
    }

    useEffect(() => {
      // if (IS_TOUCH_ENV) {
      //   return;
      // }

      if (canAutoFocus) {
        focusInput();
      }
    }, [editableInputId, conversationId, focusInput, canAutoFocus]);

    const isTouched = Boolean(getHtml());

    const className = buildClassName(styles.formControl, isTouched && 'touched');

    return (
      <div className={buildClassName(withMessageTemplatesButton && styles.withTemplatesButton)} id={id}>
        <div
          ref={inputRef}
          id={editableInputId || EDITABLE_INPUT_ID}
          className={className}
          contentEditable
          role="textbox"
          dir="auto"
          tabIndex={0}
          onClick={focusInput}
          onInput={handleChange}
          onBlur={handleChange}
          onKeyDown={handleKeyDown}
          aria-label={placeholder}
        />
        <span className="placeholder-text" dir="auto">
          {placeholder}
        </span>
      </div>
    );
  },
);

// We have overwritten declaration for forwardedRef in PaginatedFilteredTable
// NonMemoMessageInput.displayName = 'NonMemoMessageInput';

const MessageInput = memo(NonMemoMessageInput);

export default MessageInput;
