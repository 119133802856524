import { AuthContext } from '@context/auth.context';
import { useContext } from 'react';

function useAuth() {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error('No context found for AuthContext');
  }

  return authContext;
}

export default useAuth;
